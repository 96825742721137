import CloudinaryUploadWidget from "../CloudinaryUploadWidget";
import React, {useCallback, useState} from "react";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import useCloudinaryLibrary from "../PayStub/useCloudinaryLibrary";
import {Button, Card, Grid, Typography} from "@mui/material";
import LogoDisplay from "../../common/LogoDisplay";
import {Employer, useSaveLogoToEmployerMutation} from "../../graphql/generated/graphql";
import GET_EMPLOYERS from "../../graphql/queries/getEmployers";
import useAnonCookie from "../../security/useAnonCookie";
import usePayStubIdManager from "../PayStub/usePayStubIdManager";

type PropsType = {
    employerUpdated: (employerId: string) => void,
    employer: Employer;
}
const LogoDisplayEdit = (props: PropsType) => {
    const {employerUpdated, employer} = props;
    const {getAnonUserId} = useAnonCookie();
    const {getPayStubId} = usePayStubIdManager();
    const [editLogo, setEditLogo] = useState<boolean>(false);
    const [uploadError, setUploadError] = useState("");
    const {
        loaded: cloudinaryLibraryLoaded,
        uwConfig
    } = useCloudinaryLibrary();

    const [
        saveLogoToEmployer,
        {
            loading: saveLogoLoading,
            error: saveLogoError
        }
    ] = useSaveLogoToEmployerMutation({
        refetchQueries: [
            {
                query: GET_EMPLOYERS,
                variables: {
                    anonUserId: getAnonUserId(),
                },
            }
        ]
    });

    const saveLogoIfPublicIdAndEmployerId = useCallback((logoPublicId: string | undefined, employerId: number | undefined) => {
        if (!logoPublicId) {
            throw new Error("Cannot save logo. Logo public ID missing. Please contact support or try again.");
        }
        if (!employerId) {
            throw new Error("Cannot save logo. Employer ID missing. Please contact support or try again.");
        }
        saveLogoToEmployer({
            variables: {
                input: {
                    employerId: employerId.toString(),
                    logoPublicId: logoPublicId
                },
                payStubId: getPayStubId(),
                anonUserId: getAnonUserId()
            }
        })
            .then(result => {
                if (result.data && result.data.saveLogoToEmployer.successful) {
                    let savedEmployerId = result.data.saveLogoToEmployer.employerId;
                    console.log("savedEmployerId: ", savedEmployerId);
                    if (savedEmployerId !== employerId.toString()) {
                        employerUpdated(savedEmployerId);
                    }
                }
            });
    }, [saveLogoToEmployer, getPayStubId, getAnonUserId, employerUpdated]);


    const errorLoadingDisplay = <LoadingErrorDisplay loading={saveLogoLoading} apolloError={saveLogoError}/>;

    if (!employer.id) {
        return <Typography>A logo can be added after saving an employer</Typography>;
    }
    if (!editLogo && !employer.logoUrl) {
        return <>
            {errorLoadingDisplay}
            <Card sx={{height: "100%"}}>
                <Button
                    sx={{p: 1, mt: 2}}
                    fullWidth
                    onClick={() => setEditLogo(true)}
                >
                    Add Logo
                </Button>
            </Card>
        </>
    }
    const getLogoEdit = () => {
        return <>
            {
                (!!uploadError || !cloudinaryLibraryLoaded) &&
                <LoadingErrorDisplay stringError={uploadError} loading={!cloudinaryLibraryLoaded}/>
            }
            {
                cloudinaryLibraryLoaded &&
                <CloudinaryUploadWidget
                    uwConfig={uwConfig}
                    setPublicId={(logoPublicId) => saveLogoIfPublicIdAndEmployerId(logoPublicId, employer.id)}
                    uploadError={setUploadError}
                    open={editLogo}
                    close={() => setEditLogo(false)}
                />
            }
        </>
    }

    function getLogoEditButton() {
        return <Grid item xs={12}>
            <Button onClick={() => setEditLogo(true)}>
                Edit Logo
            </Button>
        </Grid>;
    }

    return <>
        <Grid item xs={12}>
            <LogoDisplay
                logoUrl={employer.logoUrl}
                logoWidth={employer.logoWidth}
                logoHeight={employer.logoHeight}
            />
        </Grid>
        {editLogo
            ? getLogoEdit()
            : getLogoEditButton()
        }
        <Grid>
            {errorLoadingDisplay}
        </Grid>
    </>
}
export default LogoDisplayEdit;