import * as React from 'react';
import {Dayjs} from 'dayjs';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateRange} from '@mui/x-date-pickers-pro/models';
import {DateRangePicker} from '@mui/x-date-pickers-pro/DateRangePicker';
import {SingleInputDateRangeField} from "@mui/x-date-pickers-pro";

type PropsType = {
    dateRange: DateRange<Dayjs>;
    setDateRange: (dateRange: DateRange<Dayjs>) => void;
    disabled: boolean;
    label: string;
}

// todo: Hawk, we had no global styles prior to me setting them on June 5th (well we had some but nothing that would interfere).
//  this still isn't showing the selected range outline inside the picker like the demo does.
//  Even though I copied this example pretty much exactly from the MUI site. Is it possible our global styles are interferring?


const OneTwoPayDateRangePicker = (props: PropsType) => {

    const {dateRange, setDateRange, disabled, label} = props;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
                value={dateRange}
                onChange={setDateRange}
                disabled={disabled}
                label={label}
                slots={{field: SingleInputDateRangeField,}}
                slotProps={{
                    textField: {
                        // size: 'small',
                        sx: {
                            // '& .MuiInputBase-root': { px: '3px', py: '8px' },
                            width: '100%',
                            maxWidth: '235px',
                            minWidth: '185px',
                        }
                    },
                    fieldSeparator: {children: 'to'},
                }}
            />
        </LocalizationProvider>
    );
}

export default OneTwoPayDateRangePicker;