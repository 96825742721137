import {withAuthenticationRequired} from "@auth0/auth0-react";
import {Typography} from "@mui/material";
import React, {ComponentType} from "react";


type PropsType = {
    component: ComponentType
}

export function RequireAuth({component}: PropsType) {


    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => (
            <div>
                <Typography>Redirecting to sign in</Typography>
            </div>
        ),
    });
    return <Component/>;
}