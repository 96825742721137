import React, {useCallback, useEffect, useState} from "react";
import {
    Deduction,
    DeductionType,
    useGetDeductionsLazyQuery,
    useGetDeductionTypesQuery
} from "../../graphql/generated/graphql";
import usePayStubIdManager from "./usePayStubIdManager";
import useGetDeductionTypeInfo from "./useGetDeductionTypeInfo";
import EditableDeductionLines from "./EditableDeductionLines";
import CalculatedDeductions, {getEmptyDeductions} from "./CalculatedDeductions";
import {Grid, IconButton, Typography} from "@mui/material";
import OneTwoPayTooltip from "../../common/OneTwoPayTooltip";
import {Add} from "@mui/icons-material";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import useAnonCookie from "../../security/useAnonCookie";
import useSystemNotices from "../../Utils/useSystemNotices";


type PropsType = {
    isEditable: boolean;
    recalculateNeeded: boolean;
    calculatedDeductionsUpdated: () => void;
    clearDeductions: boolean;
    employeeIsSet: boolean;
    deductionTypes: Array<DeductionType>;
    reload: boolean;
    reloaded: () => void;
    customDeductionsUpdated: () => void;
}

const HEALTH_PLAN_DEDUCTION_TYPE_ID = 11;


function getEmptyDeductionLine(): Array<Deduction> {
    const regularDeduction: Deduction = {
        amount: "00.00",
        currentPeriodId: 0,
        id: "", // need unique ids if providing more than one deduction line.
        deductionTypeId: HEALTH_PLAN_DEDUCTION_TYPE_ID,
        priorPeriodAmount: "00.00",
        priorPeriodId: 0,
        ytdAmount: "00.00",
        payStubId: 0,
    }
    return [regularDeduction];
}

const MAX_CALCULATED_DEDUCTION_ID = 10;

function getCustomDeductionTypes(deductionTypes: Array<DeductionType> | undefined) {
    if (!deductionTypes) {
        return undefined;
    }
    return deductionTypes.filter(deductionType => deductionType.id > MAX_CALCULATED_DEDUCTION_ID);
}

const Deductions = (props: PropsType) => {

    const {
        isEditable,
        recalculateNeeded,
        calculatedDeductionsUpdated,
        clearDeductions,
        employeeIsSet,
        deductionTypes,
        reload,
        reloaded,
        customDeductionsUpdated
    } = props;


    const {getPayStubId} = usePayStubIdManager();
    const {getAnonUserId} = useAnonCookie();
    const {sendNotice} = useSystemNotices();
    const payStubId = getPayStubId();
    const {getCustomDeductionsWithTypes, getCalculatedDeductionsWithTypes} = useGetDeductionTypeInfo();
    const [error, setError] = useState<string>();
    const emptyDeductions = getEmptyDeductions(payStubId, deductionTypes);
    const [calculatedDeductions, setCalculatedDeductions] = useState<Array<Deduction>>(emptyDeductions);
    const [customDeductions, setCustomDeductions] = useState<Array<Deduction>>([]);


    const [
        getDeductions,
        {
            data: getDeductionsData,
            loading: getDeductionsLoading,
            error: getDeductionsError
        }
    ] = useGetDeductionsLazyQuery({
        fetchPolicy: "no-cache"
    });

    const {
        data: deductionTypesData,
        loading: deductionTypesLoading,
        error: deductionTypesError
    } = useGetDeductionTypesQuery(
        {
            variables: {
                anonUserId: getAnonUserId()
            }
        }
    );

    useEffect(() => {
        if (clearDeductions) {
            setError(undefined);
            setCalculatedDeductions(emptyDeductions);
            setCustomDeductions([]);
        }
    }, [emptyDeductions, clearDeductions]);

    const loadDeductions = useCallback(() => {
        if (!payStubId || getDeductionsLoading || !!getDeductionsError) {
            return;
        }

        getDeductions({
            variables: {
                payStubId: payStubId,
                anonUserId: getAnonUserId()
            }
        })
            .then(getDeductionsResult => {
                if (!!getDeductionsResult.data?.getDeductions) {
                    reloaded();
                    setCalculatedDeductions(getDeductionsResult.data.getDeductions
                        .filter(deduction => deduction.deductionTypeId <= MAX_CALCULATED_DEDUCTION_ID));
                    setCustomDeductions(getDeductionsResult.data.getDeductions
                        .filter(deduction => deduction.deductionTypeId > MAX_CALCULATED_DEDUCTION_ID));
                } else {
                    setError("Error loading deductions. Please try again or contact support.");
                    sendNotice(`getDeductions returned but no data provided. payStubId: ${payStubId}`);
                }

            })
            .catch(error => {
                setError(error.message);
                sendNotice(`Exception caught getting deductions: ${error.message}`);
            });
    }, [reloaded, sendNotice, getDeductions, payStubId, getAnonUserId, getDeductionsLoading, getDeductionsError]);

    useEffect(() => {
        if (!getDeductionsData || reload) {
            loadDeductions();
        }
    }, [getDeductionsData, reload, loadDeductions]);


    const deleteDeduction = (id: string) => {
        setCustomDeductions(prevState => {
            if (prevState.length < 2) {
                return [];
            }
            return prevState
                .filter(deduction => deduction.id !== id);
        });
    }


    const addNewDeductionLine = () => {
        setCustomDeductions(prevState => {
            return [
                ...prevState,
                ...getEmptyDeductionLine()
            ];
        });
    }

    const customDeductionsChanged = useCallback(() => {
        loadDeductions();
        customDeductionsUpdated();
    }, [loadDeductions, customDeductionsUpdated]);


    if (!!getDeductionsError || getDeductionsLoading || !!deductionTypesError || deductionTypesLoading) {
        return <LoadingErrorDisplay
            loading={getDeductionsLoading || deductionTypesLoading}
            apolloError={getDeductionsError || deductionTypesError}
            stringError={error}
        />
    }

    const calculatedDeductionsWithTypes = getCalculatedDeductionsWithTypes(calculatedDeductions, deductionTypes);
    const customDeductionTypes = getCustomDeductionTypes(deductionTypesData?.getDeductionTypes);

    const notCalculatedDeductionsWithTypes = !!customDeductionTypes && customDeductionTypes.length > 0 && getCustomDeductionsWithTypes(customDeductions, customDeductionTypes);
    return <>
        <Grid container>
            <Grid item display="flex" alignItems="center"
                  sx={{pt: {xs:1, md:0}}}
            >
                <Typography
                    component="h4"
                    variant="h6"
                    sx={{fontWeight: 500, pl: {xs:2, md:3}}}
                >
                    Taxes and Deductions
                </Typography>

                {
                    isEditable &&
                    <OneTwoPayTooltip
                        tipContent={"Add deduction"}
                    >
                        <IconButton
                            color={"primary"}
                            sx={{ml: 1}}
                            onClick={addNewDeductionLine}
                        >
                            <Add/>
                        </IconButton>
                    </OneTwoPayTooltip>
                }
            </Grid>
        </Grid>
        <CalculatedDeductions
            deductionsWithTypes={calculatedDeductionsWithTypes}
            isEditable={isEditable}
            recalculateNeeded={recalculateNeeded}
            calculatedDeductionsUpdated={calculatedDeductionsUpdated}
            employeeIsSet={employeeIsSet}
            clearDeductions={() => setCalculatedDeductions(emptyDeductions)}
            setDeductions={setCalculatedDeductions}
        />
        {
            !!notCalculatedDeductionsWithTypes &&
            <EditableDeductionLines
                deductionsWithTypes={notCalculatedDeductionsWithTypes}
                deductionTypes={customDeductionTypes}
                payStubId={payStubId}
                isEditable={isEditable}
                deductionsUpdated={customDeductionsChanged}
                deleteDeduction={deleteDeduction}
            />
        }
    </>
}

export default Deductions;