import React from "react";
import {Employer} from "../../graphql/generated/graphql";
import {Box, Grid, Typography} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import OneTwoPayTooltip from "../../common/OneTwoPayTooltip";

type PropsType = {
    employer?: Employer;
    edit: () => void;
    noEmployerDisplay: ReactJSXElement;
    isEditable: boolean;
}
const EmployerDisplay = (props: PropsType) => {
    const {employer, noEmployerDisplay, edit, isEditable} = props;

    if (!employer || employer.id < 1) {
        return noEmployerDisplay;
    }
    return <Grid item display="flex" alignItems="center">
        <Box ml={2}>
            <Typography variant={'h4'}>{employer.name}
                {isEditable &&
                    <OneTwoPayTooltip tipContent={"Edit employer"} >
                        <EditIcon
                            color={"primary"}
                            sx={{ml: 2}}
                            onClick={() => edit()}
                        />
                    </OneTwoPayTooltip>
                }
            </Typography>
            <Typography variant={'body1'}>{employer.address1}</Typography>
            <Typography variant={'body1'}>{employer.address2}</Typography>
            <Typography variant={'body1'}>
                {employer.city}, {employer.province}, {employer.postalCode?.toUpperCase()}
            </Typography>
        </Box>
    </Grid>
}
export default EmployerDisplay;