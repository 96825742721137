import {Box, CircularProgress, Typography} from "@mui/material";
import React, {forwardRef} from "react";
import {ApolloError} from "@apollo/client";
import ErrorDisplay from "./ErrorDisplay";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";

type PropsType = {
    loading: boolean;
    loadingMessage?: string;
    apolloError?: ApolloError | undefined;
    stringError?: string | Array<string> | undefined | null;
    children?: Array<ReactJSXElement> | ReactJSXElement;
}

const LoadingErrorDisplay = forwardRef<HTMLDivElement, PropsType>((props, ref) => {
    const {loading, apolloError, stringError, children, loadingMessage} = props;

    if (loading) {
        return <>
            <CircularProgress/>
            {
                !!loadingMessage &&
                <Typography
                    sx={{mx: '100px', fontSize: 18, fontWeight: 500, color: 'primary.main'}}
                >{loadingMessage}
                </Typography>
            }
        </>
    }

    return (
        <Box ref={ref}>
            {children}
            <ErrorDisplay
                apolloError={apolloError}
                stringError={stringError}
            />
        </Box>
    );
});

export default LoadingErrorDisplay;