import {useEffect, useState} from "react";


type UwConfig = {
    cloudName: string;
    uploadPreset: string;
}


type CloudinaryLibraryReturnType = {
    uwConfig: UwConfig;
    loaded: boolean;
}


const useCloudinaryLibrary = (): CloudinaryLibraryReturnType => {
    const [cloudinaryLibraryLoaded, setCloudinaryLibraryLoaded] = useState(false);

    const cloudName = "onetwopay";
    const uploadPreset = "q3ggf1tj";
    // Note that these are only a few examples, to see
    // the full list of possible parameters that you
    // can add see:
    //   https://cloudinary.com/documentation/upload_widget_reference

    const [uwConfig] = useState({
        cloudName,
        uploadPreset,
        // cropping: true, //add a cropping step
        // showAdvancedOptions: true,  //add advanced options (public_id and tag)
        // sources: [ "local", "url"], // restrict the upload sources to URL and local files
        multiple: false,  //restrict upload to a single file
        // folder: "user_images", //upload files to the specified folder
        // tags: ["users", "profile"], //add the given tags to the uploaded files
        // context: {alt: "user_uploaded"}, //add the given context data to the uploaded files
        // clientAllowedFormats: ["images"], //restrict uploading to image files only
        // maxImageFileSize: 2000000,  //restrict file size to less than 2MB
        // maxImageWidth: 2000, //Scales the image down to a width of 2000 pixels before uploading
        // theme: "purple", //change to a purple theme
    });

    function scriptLoadedCallback() {
        setCloudinaryLibraryLoaded(true);
    }

    useEffect(() => {
        if (!cloudinaryLibraryLoaded) {
            let uploadWidgetScriptId = "uw";
            const uwScript = document.getElementById(uploadWidgetScriptId) as HTMLScriptElement;
            if (!uwScript) {
                const script = document.createElement("script");
                script.async = true;
                script.id = uploadWidgetScriptId;
                script.src = "https://upload-widget.cloudinary.com/global/all.js";
                script.onload = scriptLoadedCallback;
                document.body.appendChild(script);
            } else if (!!window.cloudinary && !!window.cloudinary.createUploadWidget) {
                setCloudinaryLibraryLoaded(true);
            } else {
                uwScript.onload = scriptLoadedCallback;
            }
        }
    }, [cloudinaryLibraryLoaded]);


    return {
        uwConfig: uwConfig,
        loaded: cloudinaryLibraryLoaded
    }
}

export default useCloudinaryLibrary;