import React from "react";
import {UseFormRegister} from "react-hook-form";
import {FormControlLabel, Grid, Switch} from "@mui/material";
import {FIELD_ARRAY_NAME, FillableParam, FormValues} from "./ReportParamterFormField";
import OneTwoPayTooltip from "../../../common/OneTwoPayTooltip";


type PropsType<T extends FillableParam> = {
    field: T & Record<"id", string>;
    // errors: FieldErrors<FormValues>;
    // required: boolean;
    register: UseFormRegister<FormValues>;
    index: number;
}

const ReportParameterFormCheckboxField = <T extends FillableParam>(props: PropsType<T>) => {
    const {
        field,
        // errors,
        register,
        // required,
        index
    } = props;

    // let options = undefined;
    // if (required) {
    //     options = {required: `${field.name} is required`};
    // }
    // const error = !!errors.params ? errors.params[index] : undefined
    // const errorMessage = !!error ? error.value?.message : undefined;
    return <Grid container spacing={2} alignItems="center">
        <Grid item md={3}>
            <FormControlLabel
                control={
                    <Switch
                        {...register(`${FIELD_ARRAY_NAME}.${index}.value`)}
                        color="primary"
                    />
                }
                label={
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {field.name}
                        <OneTwoPayTooltip
                            tipContent={field.description}
                        />
                    </div>
                }
            />
        </Grid>
    </Grid>
        ;
}

export default ReportParameterFormCheckboxField;