import {UserSettingType, useSaveUserSettingMutation} from "../graphql/generated/graphql";
import {useCallback} from "react";
import useSystemNotices from "../Utils/useSystemNotices";

export const REACT_CLIENT_APP_PREFERENCE: string = "REACT_CLIENT";
export const PHP_CLIENT_APP_PREFERENCE: string = "PHP_CLIENT";

type ReturnType = {
    saveAppPreference: (preferredApp: string, email: string | null | undefined) => void
}
export const useSaveAppPreference = (): ReturnType => {
    const [saveUserSetting] = useSaveUserSettingMutation();
    const {sendNotice} = useSystemNotices();

    const saveAppPreference = useCallback((preferredApp: string, email: string | null | undefined) => {
        saveUserSetting({
            variables: {
                userSettingType: UserSettingType.AppPreference,
                value: preferredApp,

            }
        })
            .then(result => {
                if (!result.data?.saveUserSetting.id) {
                    sendNotice(`Failed to save user setting APP_PREFERENCE for user: ${email || ""}`)
                }
            })
            .catch(error => {
                sendNotice(`Failed to save user setting APP_PREFERENCE for user: ${email || ""}. Error: ${error.message}`);
            });
    }, [sendNotice, saveUserSetting]);


    return {
        saveAppPreference
    }
}