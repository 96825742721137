import {styled} from "@mui/system";
import {Backdrop, Paper} from "@mui/material";

export const StyledPaper = styled(Paper)(({theme}) => ({
    padding: '16px',
    margin: '16px',
    [theme.breakpoints.down('sm')]: {
        padding: "8px",
        margin: 0
    }
}));
export const TaxBackdrop = styled(Backdrop)`
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.1);
`;


export const TaxContents = styled('div')`
  position: relative;
`;
