import {FieldErrors, FieldPath, FieldValues, Path, UseFormRegister} from "react-hook-form";
import {Control} from "react-hook-form/dist/types";
import ReportParameterFormSelectField from "./ReportParameterFormSelectField";
import React from "react";
import ReportParameterFormTextField from "./ReportParameterFormTextField";
import {MenuItem} from "@mui/material";
import {ReportParamType} from "../../../graphql/generated/graphql";
import ReportParameterFormCheckboxField from "./ReportParameterFormCheckboxField";
import ErrorDisplay from "../../../common/ErrorDisplay";
import ReactHookFormDatePicker from "../../../common/ReactHookFormDatePicker";
import ReactHookFormDateRangePicker from "../../../common/ReactHookFormDateRangePicker";

export const FIELD_ARRAY_NAME = 'params';

export type FillableParam = {
    name: string;
    graphId: string;
    type: ReportParamType;
    value: Array<string>;
    description: string;
    required: boolean;
    selectOptions: Array<FillableParamSelectOption> | undefined | null
}

export type FillableParamSelectOption = {
    defaultSelected: boolean,
    value: string,
    label: string
}

export type FormValues = {
    params: Array<FillableParam>;
}

export type ReportParamFormBasePropsType<T extends FillableParam, V extends FieldValues> = {
    field: T & Record<"id", string>;
    name: FieldPath<V>
    errors: FieldErrors<FormValues>;
    required: boolean;
    register: UseFormRegister<FormValues>;
    index: number;
    control: Control<V>, // T is the class of the object controlled by the form.
}

function getUnexpectedSystemErrorDisplayWithSupportInstructions() {
    return <ErrorDisplay
        stringError={"Report parameter type not recognized. Please contact OneTwoPay support via online chat, email (support@onetwopay.ca) or phone 1-800-837-8793."}
    />;
}

const ReportParameterFormField = <T extends FillableParam, V extends FieldValues>(props: ReportParamFormBasePropsType<T, V>) => {

    const {
        field,
        name,
        errors,
        register,
        control,
        required,
        index
    } = props;


    switch (field.type) {
        case ReportParamType.Year:
            if (field.selectOptions && field.selectOptions.length > 0) {
                return <ReportParameterFormSelectField<T, V>
                    field={field}
                    errors={errors}
                    required={required}
                    register={register}
                    index={index}
                    control={control}
                    name={name}
                >
                    {field.selectOptions.map((option) => {
                            return (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                    selected={option.defaultSelected}
                                >
                                    {option.label}
                                </MenuItem>
                            );
                        }
                    )
                    }
                </ReportParameterFormSelectField>
            }
            return getUnexpectedSystemErrorDisplayWithSupportInstructions();
        case ReportParamType.Boolean:
            return <ReportParameterFormCheckboxField
                field={field}
                register={register}
                index={index}
            />
        case ReportParamType.MoneyAmount:
        case ReportParamType.String:
            return <ReportParameterFormTextField<T>
                field={field}
                errors={errors}
                required={required}
                register={register}
                index={index}
            />
        case ReportParamType.Date:
            return <ReactHookFormDatePicker<V>
                fieldName={field.id as Path<V>}
                label={field.name}
                control={control}/>
        case ReportParamType.DateRange:
            // todo: need to review this getFullFieldDataObject idea. It works but I'm not sure it makes sense.
            //  Seems like there should be a simpler design for making the child component able to update the entire field object instead of just field.value.
            // Or maybe all these child components should be simpler and only know about the values they are responsible for. hmmm. that seems like a better design.
            // Ok. how can I make this element able to get the value from the child and then update the form with the full field object? Without the child knowing anything about it.
            return <ReactHookFormDateRangePicker<V>
                fieldName={`${FIELD_ARRAY_NAME}.${index}` as Path<V>}
                label={field.name}
                control={control}
                getFullFieldDataObject={dateValues => (
                    {
                        ...field,
                        value: dateValues
                    }
                )}
            />
        default:
            return getUnexpectedSystemErrorDisplayWithSupportInstructions();
    }

}

export default ReportParameterFormField;