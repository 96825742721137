import {RoeReportParameterInput} from "../graphql/generated/graphql";

const ROE_INPUT_KEY = 'roeInput';

type RoeInputWithSeconds = {
    roeInput: RoeReportParameterInput;
    timeInSeconds: number;
}

function getRoeInputKey(employeeId: string) {
    return `${ROE_INPUT_KEY}_${employeeId}`;
}

function getTimeInSeconds() {
    return Math.floor(new Date().getTime() / 1000);
}

export function saveRoeReportInputToLocalStorage(roeInput: RoeReportParameterInput, employeeId: string) {
    const toSave: RoeInputWithSeconds = {
        roeInput: roeInput,
        timeInSeconds: getTimeInSeconds()
    };
    localStorage.setItem(getRoeInputKey(employeeId), JSON.stringify(toSave));
}

// since we don't persist the input on the server, we save it locally in case the page gets refreshed due to an expired token or some other reason.
export function getRoeReportInputFromLocalStorageIfLessThan20SecondsOld(employeeId: string) {
    const roeInputAsJson = localStorage.getItem(getRoeInputKey(employeeId));
    if (!!roeInputAsJson) {

        const inputWithSeconds = JSON.parse(roeInputAsJson) as RoeInputWithSeconds;
        if (getTimeInSeconds() - inputWithSeconds.timeInSeconds < 20) {
            return inputWithSeconds.roeInput;
        }
    }
    return undefined;
}