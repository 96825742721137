import {
    EmployeeWithStatus,
    ReportParam,
    ReportParamsInput,
    SelectOption,
    useGenerateReportLazyQuery
} from "../../../graphql/generated/graphql";
import {Box, Button, Grid, Typography} from "@mui/material";
import React, {useState} from "react";
import {useFieldArray, useForm, useWatch} from "react-hook-form";
import ReportParameterFormField, {
    FIELD_ARRAY_NAME,
    FillableParam,
    FillableParamSelectOption,
    FormValues
} from "./ReportParamterFormField";
import {Maybe} from "yup";
import LoadingErrorDisplay from "../../../common/LoadingErrorDisplay";

type PropsType = {
    params: Array<ReportParam>;
    reportId: string;
    close: () => void;
    selectedEmployees: Array<EmployeeWithStatus>;
}

function getAllSelectOptions(param: ReportParam): Array<FillableParamSelectOption> | undefined {
    return param.selectOptions?.map(paramSelectOption => ({
        defaultSelected: paramSelectOption.defaultSelected,
        value: paramSelectOption.value,
        label: paramSelectOption.label,
    }));
}

function getSelectedOptions(selectOptions: Maybe<Array<SelectOption>> | undefined) {
    if (!selectOptions || selectOptions.length < 1) {
        return [];
    }
    return selectOptions.filter(option => option.defaultSelected)
        .map(option => option.value);
}

const getSortedFieldsForForm = (params: Array<ReportParam>): Array<FillableParam> => {
    const sortedParams = [...params]; // Was getting errors if I tried to sort the params array directly.
    return sortedParams.sort((a, b) => a.displayOrder - b.displayOrder)
        .map(param => {
                return {
                    name: param.name,
                    value: getSelectedOptions(param.selectOptions),
                    graphId: param.id,
                    type: param.type,
                    required: param.required,
                    selectOptions: getAllSelectOptions(param),
                    description: param.description
                }
            }
        );
}


function blankIfPdf(link: string) {
    const url = new URL(link);
    return url.pathname.endsWith(".pdf") ? "_blank" : "";
}

const ReportParametersForm = (props: PropsType) => {

    const {
        params,
        close,
        reportId,
        selectedEmployees
    } = props;

    const [reportLink, setReportLink] = useState<string | undefined | null>();
    const [reportMessage, setReportMessage] = useState<string | undefined | null>();

    const [generateReport,
        {
            loading: generateReportLoading,
            error: generateReportError
        }] = useGenerateReportLazyQuery({fetchPolicy: "no-cache"});


    let fieldsForForm = getSortedFieldsForForm(params);

    const {
        register,
        control,
        handleSubmit,
        formState: {errors}
    } = useForm<FormValues>({
        defaultValues: {
            params: fieldsForForm,
        },
        values: {
            params: fieldsForForm, // added this because the form needs to update if the params change.
        }
    });

    const {fields} = useFieldArray({name: FIELD_ARRAY_NAME, control,});

    // todo: need to fix the "required" attribute in the ReactHookFormDateRangePicker so that this code isn't needed.
    // todo; would be better if we didn't have to hard code this DATE_RANGE param field name because it is defined in the database and could change anytime.
    const watchedFields = useWatch({name: FIELD_ARRAY_NAME, control})
    const dateRangeFields = watchedFields.filter(field => field.type === "DATE_RANGE")
    const dateRangeNotPresentOrPresentAndCompleted = dateRangeFields.length === 0 || dateRangeFields.every(field => {
        const startDate = field.value[0]
        const endDate = field.value[1]
        return startDate !== undefined && endDate !== undefined;
    })
    const atLeastOneEmployeeSelected = selectedEmployees.length > 0

    const fieldsCompleted = atLeastOneEmployeeSelected && dateRangeNotPresentOrPresentAndCompleted;

    const generate = (data: FormValues) => {
        setReportLink(undefined);
        setReportMessage(undefined);
        const reportParameters: ReportParamsInput = {
            id: reportId,
            params: data.params.map(completedFormParam => (
                {
                    id: completedFormParam.graphId,
                    name: completedFormParam.name,
                    value: completedFormParam.value,
                    type: completedFormParam.type
                }
            )),
            employeeIds: selectedEmployees.map(selectedEmployee => selectedEmployee.employee.id.toString())
        };
        generateReport({variables: {params: reportParameters}})
            .then(result => {
                let generated = result.data?.generateReport.generated;
                const reportMessageStart = generated ? "Report generated. " : "Report not generated. ";
                setReportMessage(`${reportMessageStart} ${result.data?.generateReport.message}`);
                if (generated) {
                    setReportLink(result.data?.generateReport.link);
                }
            })
            .catch(() => { /* do nothing. Error handled by generateReportError*/
            })
    }

    const downloadButton = !!reportLink &&
        <Box display="flex" justifyContent="flex-end">
            <Button
                variant={"contained"}
                href={reportLink}
                style={{fontSize: '1.2rem'}}
                target={blankIfPdf(reportLink)}
                sx={{
                    width: {xs: '100%', sm: 'auto'},
                    minWidth: {sm: "190px"}
                }}
            >
                Download Report
            </Button>
        </Box>;

    return <form noValidate onSubmit={handleSubmit(generate)}>
        <Grid container justifyContent="space-between" alignItems="center" columnSpacing={5} rowSpacing={1.5}>
            <Grid item xs={12}/>
            {fields.map((field, index) => {
                    return <Grid item xs={12}>
                        <ReportParameterFormField<FillableParam, FormValues>
                            field={field}
                            errors={errors}
                            required={field.required}
                            register={register}
                            index={index}
                            control={control}
                            name={`${FIELD_ARRAY_NAME}.${index}.value`}
                        >
                        </ReportParameterFormField>
                    </Grid>

                }
            )}
            <Grid item xs={12}>
                {(generateReportLoading || !!generateReportError || reportMessage) &&
                    (<LoadingErrorDisplay
                        loading={generateReportLoading}
                        apolloError={generateReportError}
                        stringError={reportMessage}
                    />)
                }
            </Grid>
            <Grid item xs={12} sm={4} sx={{order: {xs: 3, sm: 1}}}>
                <Button
                    variant={"contained"}
                    style={{fontSize: '1.2rem'}}
                    color={"error"}
                    onClick={() => {
                        close();
                    }}
                    sx={{width: {xs: '100%', sm: 'auto'}}}
                >
                    {"Cancel"}
                </Button>
            </Grid>
            <Grid item xs={12} sm={4} display="flex" justifyContent="flex-end" sx={{order: {xs: 2, sm: 2}}}>
                <Button
                    variant={"contained"}
                    type={"submit"}
                    style={{fontSize: '1.2rem'}}
                    disabled={!fieldsCompleted}
                    sx={{width: {xs: '100%', sm: 'auto'}}}
                >
                    Generate report
                </Button>
            </Grid>
            <Grid item xs={12} sm={4} display="flex" justifyContent="flex-end" sx={{order: {xs: 1, sm: 3}}}>
                {!!reportLink && downloadButton}
            </Grid>
        </Grid>
        {!fieldsCompleted &&
            <Typography variant={"h6"} sx={{mt: 2}}>Please select at least one employee and complete any other fields
                provided to continue</Typography>}
    </form>;
}

export default ReportParametersForm;