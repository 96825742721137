import {Controller, FieldPath, FieldValues, Path} from "react-hook-form";
import React from "react";
import {Control} from "react-hook-form/dist/types";
import {
    DATE_FORMAT,
    getDateFromNullableStringOrDate,
    getFormattedDateStringFromNullableDateOrString
} from "../Utils/dateFormatter";
import {Clear} from "@mui/icons-material";
import OneTwoPayTooltip from "./OneTwoPayTooltip";
import {DatePicker} from "@mui/x-date-pickers";
import Box from "@mui/material/Box";

type PropsType<TFormValues extends FieldValues> = {
    fieldName: FieldPath<TFormValues>;
    label: string;
    control: Control<TFormValues>;
    required?: boolean;
    height?: number;
}

const ReactHookFormDatePicker = <TFormValues extends FieldValues>(props: PropsType<TFormValues>) => {

    const {fieldName, control, label, required, height = 45} = props;

    const name = fieldName as Path<TFormValues>;
    return <Controller
        name={name}
        control={control}
        rules={{required: required ? `${name} is required` : false}}
        render={({field}) => {
            return <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <DatePicker
                    label={label}
                    value={getDateFromNullableStringOrDate(field.value)}
                    format={DATE_FORMAT}
                    onChange={(newValue) => {
                        field.onChange(getFormattedDateStringFromNullableDateOrString(newValue))
                    }}
                    sx={{
                        width: '85%',
                        '& .MuiInputBase-root': {
                            height: height, zIndex: 1
                        },
                        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                            backgroundColor: 'white',
                            paddingRight: '5px',
                            transform: 'translate(10px, -8px) scale(0.75)',
                            zIndex: 2
                        },
                        '& .MuiInputBase-input': {
                            fontSize: '1.2rem',
                            paddingBottom: 2
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            top: -5,
                        },
                    }}
                />


                <OneTwoPayTooltip
                    spaceLeft={"5px"}
                    tipContent="Clear date">
                    <Clear
                        fontSize={"large"}
                        onClick={() => field.onChange(null)}
                    />
                </OneTwoPayTooltip>

            </Box>
        }}
    />
}
export default ReactHookFormDatePicker;