import React from "react";
import {Controller, FieldValues} from "react-hook-form";
import {RegisterOptions} from "react-hook-form/dist/types";
import {FormControl, Grid, InputLabel, Select} from "@mui/material";
import FormHelperText from '@mui/material/FormHelperText';
import {FIELD_ARRAY_NAME, FillableParam, FormValues, ReportParamFormBasePropsType} from "./ReportParamterFormField";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import ErrorDisplay from "../../../common/ErrorDisplay";


type PropsType<T extends FillableParam, V extends FieldValues> = ReportParamFormBasePropsType<T, V> & {
    children: Array<ReactJSXElement>
}
const ReportParameterFormSelectField = <T extends FillableParam, V extends FieldValues>(props: PropsType<T, V>) => {
    const {
        field,
        name,
        errors,
        register,
        children,
        control,
        required,
        index
    } = props;
    let options: RegisterOptions<FormValues, `params.${number}.value`> | undefined = undefined;
    if (required) {
        options = {required: `${field.name} is required`};
    }
    const error = !!errors.params ? errors.params[index] : undefined
    const errorMessage = !!error ? error.value?.message : undefined;
    const labelId = `${field.graphId}-label`;
    return <Grid item sm={4}>
        <FormControl
            fullWidth
        >
            <InputLabel id={labelId} sx={{
                fontSize: {
                    xs: "1.25rem",
                    sm: '1.5rem',
                },
            }}>{field.name}</InputLabel>
            <Controller
                render={({field: {onChange, value}}) => {
                    // purposefully ignoring the name and onChange that register returns because we don't need them.
                    const {onBlur, ref} = register(`${FIELD_ARRAY_NAME}.${index}.value`, options);
                    return (
                        <Select
                            value={value}
                            error={!!error}
                            onBlur={onBlur}
                            ref={ref}
                            onChange={onChange}
                            sx={{
                                fontSize: {
                                    sm: '1.5rem',
                                }
                            }}
                            label={field.name}
                        >
                            {children}
                        </Select>
                    );
                }}
                control={control}
                name={name}
            />
            <FormHelperText>
                <ErrorDisplay
                    stringError={errorMessage}
                />
            </FormHelperText>
        </FormControl>
    </Grid>
        ;
}

export default ReportParameterFormSelectField;