import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import {TaxBackdrop, TaxContents} from "./payStubStyles";
import React, {useCallback, useState} from "react";
import {
    Deduction,
    DeductionItemInput,
    DeductionType,
    useCalculateDeductionsMutation,
    useSaveCalculatedDeductionsMutation
} from "../../graphql/generated/graphql";
import EditableCoreDeductionLine from "./EditableCoreDeductionLine";
import {styled} from "@mui/system";
import usePayStubIdManager from "./usePayStubIdManager";
import useAnonCookie from "../../security/useAnonCookie";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import useSystemNotices from "../../Utils/useSystemNotices";
import {DeductionWithType} from "./useGetDeductionTypeInfo";
import {getNumber, subtractStrings} from "../../Utils/stringMath";
import theme from "../../theme";


type PropsType = {
    deductionsWithTypes: Array<DeductionWithType>;
    isEditable: boolean;
    recalculateNeeded: boolean;
    calculatedDeductionsUpdated: () => void;
    employeeIsSet: boolean;
    clearDeductions: () => void;
    setDeductions: (deductions: Array<Deduction>) => void;
}

const INCOME_TAX_DEDUCTION_TYPE_ID = 3;

function getDeductionItemInput(deductionWithType: DeductionWithType, payStubId: number): DeductionItemInput {
    const deduction = deductionWithType.deduction;
    return {
        amount: deduction.amount,
        ytdAmount: deduction.ytdAmount,
        priorPeriodAmount: subtractStrings(deduction.ytdAmount, deduction.amount),
        currentPeriodId: deduction.currentPeriodId,
        deductionTypeId: deduction.deductionTypeId,
        payStubId: payStubId,

    };
}

function getDeductionInputs(deductions: Array<DeductionWithType> | undefined, payStubId: number): Array<DeductionItemInput> {
    if (!deductions) {
        return [];
    }
    return deductions.map(deduction => getDeductionItemInput(deduction, payStubId));
}

export function getEmptyDeductions(payStubId: number | undefined, deductionTypes: Array<DeductionType>): Array<Deduction> {
    let count = 0;
    return deductionTypes
        .filter(deductionType => deductionType.id !== INCOME_TAX_DEDUCTION_TYPE_ID)
        .map(deductionType => {
            return {
                amount: "00.00",
                currentPeriodId: 0,
                deductionTypeId: deductionType.id,
                id: String(count++),
                priorPeriodAmount: "00.00",
                priorPeriodId: 0,
                ytdAmount: "00.00",
                payStubId: payStubId || 0
            }
        });
}

const StyledTableHeaderCell = styled(TableCell)(({theme}) => ({
    fontSize: '1.25rem',
    fontWeight: 600,
    [theme.breakpoints.up('lg')]: {
        fontSize: '1.6rem',
    },
}));


function getSortedDeductions(deductionsWithTypes: Array<DeductionWithType>) {
    if (!deductionsWithTypes || deductionsWithTypes.length < 1) {
        return [];
    }
    const deductionsCopy = [...deductionsWithTypes];
    return deductionsCopy.sort((a, b) => a.deductionType.displayOrder - b.deductionType.displayOrder);
}


function validateDeduction(updatedDeduction: DeductionItemInput): string | undefined {
    let amount = getNumber(updatedDeduction.amount);
    let ytdAmount = getNumber(updatedDeduction.ytdAmount);
    if (amount > ytdAmount) {
        return "YTD amount cannot be less than the current period amount";
    }
    return undefined;
}

function validateDeductions(deductionInputs: Array<DeductionItemInput>): string | undefined {
    const validationResults = deductionInputs.map(deduction => validateDeduction(deduction));
    return validationResults.find(result => !!result);
}

const CalculatedDeductions = (props: PropsType) => {

    const {
        isEditable,
        recalculateNeeded,
        calculatedDeductionsUpdated,
        clearDeductions,
        employeeIsSet,
        deductionsWithTypes,
        setDeductions,
    } = props;

    const {getPayStubId} = usePayStubIdManager();
    const payStubId = getPayStubId();
    const [editMode, setEditMode] = useState<boolean>(false);
    const [error, setError] = useState<string | null | undefined>();
    const [craOutageMessage, setCraOutageMessage] = useState<string>();
    const {getAnonUserId} = useAnonCookie();
    const {sendNotice} = useSystemNotices();

    const [
        calculateDeductions,
        {
            loading: calculateLoading,
            error: calculateError
        }
    ] = useCalculateDeductionsMutation();

    const [
        saveDeductions,
        {
            loading: saveDeductionsLoading,
            error: saveDeductionsError
        }
    ] = useSaveCalculatedDeductionsMutation();


    const saveToServer = useCallback(() => {
        setError(undefined);
        if (!payStubId) {
            setError("Please add an employee before trying to save deductions");
            sendNotice(`Trying to save deductions without pay stub id. No action required. User asked to add employee first.`);
            return;
        }
        let deductionInputs = getDeductionInputs(deductionsWithTypes, payStubId);
        let validationMessage = validateDeductions(deductionInputs);
        if (!!validationMessage) {
            setError(validationMessage);
            return;
        }
        saveDeductions({
            variables: {
                deductionItems: deductionInputs,
                payStubId: payStubId,
                anonUserId: getAnonUserId()
            }
        })
            .then(saveDeductionsResult => {
                if (!!saveDeductionsResult.data?.saveCalculatedDeductions) {
                    setDeductions(saveDeductionsResult.data.saveCalculatedDeductions);
                    setEditMode(false);
                    calculatedDeductionsUpdated();
                }
            });
    }, [deductionsWithTypes, payStubId, sendNotice, saveDeductions, getAnonUserId, setDeductions, calculatedDeductionsUpdated]);

    const calculate = useCallback(() => {

        const craOutageMessage = process.env.REACT_APP_CRA_OUTAGE;
        if (!!craOutageMessage) {
            setCraOutageMessage(craOutageMessage);
            return;
        }

        clearDeductions();
        setError(undefined);
        if (!employeeIsSet) {
            setError("Please add an employee before calculating deductions.");
            return;
        }
        if (!payStubId) {
            setError("Please add an employee or income line before calculating deductions.");
            sendNotice("Calculate called but no payStubId set. We should never get this notice. Need to review this logic if we do.");
            return;
        }
        calculateDeductions({
            variables: {
                payStubId: payStubId,
                anonUserId: getAnonUserId()
            }
        })
            .then(calculationResult => {
                let data = calculationResult.data;
                if (!!data?.calculateDeductions) {
                    setDeductions(data.calculateDeductions.deductions);
                    if (data.calculateDeductions.successful) {
                        calculatedDeductionsUpdated();
                        return;
                    }
                    console.log("data: ", JSON.stringify(data, null, 2));
                    setError(data.calculateDeductions.message);
                    return;
                }
                setError("Error calculating deductions");
            })
            .catch(error => {
                sendNotice(`Apollo error returned from calculate endpoint: ${error}`);
                setError(error);
            })

    }, [clearDeductions, calculatedDeductionsUpdated, payStubId, getAnonUserId, calculateDeductions, setDeductions, sendNotice, employeeIsSet]);

    const saveDeduction = useCallback((updatedDeduction: Deduction) => {
        const deductionsToSave = deductionsWithTypes
            .map(deductionWithType => deductionWithType.deduction)
            .filter(deduction => deduction.id !== updatedDeduction.id);
        deductionsToSave.push(updatedDeduction);
        setDeductions(deductionsToSave);
    }, [deductionsWithTypes, setDeductions]);


    const sortedDeductions = !!deductionsWithTypes ? getSortedDeductions(deductionsWithTypes) : undefined;

    if (!!craOutageMessage) {
        return <Dialog
            open={!!craOutageMessage}
        >
            <DialogTitle>
                CRA calculator unavailable
            </DialogTitle>
            <DialogContent>
                {craOutageMessage}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    type={"button"}
                    size={"large"}
                    onClick={() => setCraOutageMessage(undefined)}
                >
                    Got it
                </Button>
            </DialogActions>

        </Dialog>
    }

    return <>
        <Grid container
              display="flex"
              justifyContent="space-between"
              columnSpacing={2}
              sx={{
                  mt: 1,
                  mb: {xs: 1, sm: 1.25},
                  px: {xs: 1}
              }}
        >
            {editMode ?
                <>
                    <Grid item xs={6} display="flex" alignItems="center">
                        <Button
                            sx={{py: {xs: 1.75, sm: 0.75}}}
                            variant={'contained'}
                            color="primary"
                            fullWidth
                            onClick={() => {
                                saveToServer();
                            }}
                        >
                            Save
                        </Button>
                    </Grid>
                    <Grid item xs={6} display="flex" alignItems="center">
                        <Button
                            variant={'outlined'}
                            sx={{py: {xs: 1.75, sm: 0.75}}}
                            color="error"
                            fullWidth
                            onClick={() => {
                                setEditMode(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </>
                :
                <>
                    <Grid item xs={6} display="flex" alignItems="center">
                        <Button
                            sx={{
                                backgroundColor: theme.palette.grey[100],
                                py: {xs: 1.75, sm: 0.75}
                            }}
                            variant={'contained'}
                            color="inherit"
                            disabled={!isEditable}
                            fullWidth
                            onClick={() => {
                                setEditMode(true);
                                setError(undefined);
                            }}
                        >
                            Use My Own Calculations
                        </Button>

                    </Grid>
                    <Grid item xs={6} display="flex" alignItems="center">
                        <Button
                            variant={'contained'}
                            sx={{py: {xs: 1.75, sm: 0.75}}}
                            color="primary"
                            disabled={!isEditable}
                            fullWidth
                            onClick={() => calculate()}>
                            Calculate cra deductions
                        </Button>
                    </Grid>
                </>

            }
        </Grid>

        {
            (saveDeductionsLoading || !!saveDeductionsError || !!error) &&
            <LoadingErrorDisplay
                loading={saveDeductionsLoading}
                apolloError={saveDeductionsError}
                stringError={error}
            />
        }

        <TaxContents sx={{
            border: '1px solid #d3d3d3',
            borderRadius: 'o.75rem',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            py: 1,
            mx: 1
        }}>

            <Table
                size="small"
                sx={{
                    '& .MuiTableCell-root': {
                        borderBottom: 'none',
                        borderLeft: 'none',
                        borderRight: 'none',
                        borderTop: 'none',

                    },
                }}
            >
                <TableHead>
                    <TableRow>
                        <StyledTableHeaderCell>Deduction</StyledTableHeaderCell>
                        <StyledTableHeaderCell>Amount</StyledTableHeaderCell>
                        <StyledTableHeaderCell>YTD</StyledTableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!!sortedDeductions &&
                        sortedDeductions.map((deductionWithType: DeductionWithType) => (
                            <EditableCoreDeductionLine
                                deductionWithType={deductionWithType}
                                saveDeduction={saveDeduction}
                                isTaxEditable={editMode}
                                key={deductionWithType.deduction.id}
                            />

                        ))}
                </TableBody>
            </Table>
            <TaxBackdrop open={recalculateNeeded && !editMode}>
                {
                    (calculateLoading || !!calculateError) &&
                    <LoadingErrorDisplay
                        apolloError={calculateError}
                        loading={calculateLoading}
                    />
                }
            </TaxBackdrop>
        </TaxContents>
    </>
}

export default CalculatedDeductions;