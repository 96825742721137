import {Control} from "react-hook-form/dist/types";
import {FormControlLabel, Switch} from "@mui/material";
import {Controller, FieldValues, Path} from "react-hook-form";
import React from "react";


type PropsType<TFormValues extends FieldValues> = {
    fieldName: keyof TFormValues;
    label: string;
    control: Control<TFormValues>;

}

const ReactFormSwitch = <TFormValues extends FieldValues>(props: PropsType<TFormValues>) => {

    const {control, label, fieldName} = props;
    return <Controller
        name={fieldName as Path<TFormValues>}
        control={control}
        render={({field: {onChange, ref, value, ...rest}}) => (
            <FormControlLabel
                control={
                    <Switch
                        {...rest}
                        onChange={(event) => onChange(event.target.checked)}
                        checked={value}
                        inputRef={ref}
                    />
                }
                label={label}
            />
        )}
    />
}

export default ReactFormSwitch;