import React, {FC} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

type AnnouncementBarProps = {
    url: string;
    title: string;
    linkText: string;
}

const AnnouncementBar: FC<AnnouncementBarProps> = ({url, title, linkText}) => {
    return (
        <AppBar position="static" color="primary" sx={{height: 50}}>
            <Toolbar variant="dense" sx={{justifyContent: 'center', display: 'flex'}}>
                <Typography variant="h6" color="inherit" sx={{textAlign: 'center', flex: 1}}>
                    {title}
                    <a href={url}
                       target="_blank"
                       rel="noopener noreferrer"
                       style={{color: 'white', fontWeight: 'bold'}}>
                        {linkText}
                    </a>
                </Typography>
            </Toolbar>
        </AppBar>
    );
};

export default AnnouncementBar;