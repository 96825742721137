import React from "react";
import {PayStubWithDeductions} from "../../graphql/generated/graphql";
import {Grid, Typography} from "@mui/material";

type PropsType = {
    payStub: PayStubWithDeductions
}

const PreviousPayStubDisplay = (props: PropsType) => {
    const {payStub} = props;

    return <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item>
            Previous Pay Stub
        </Grid>
        <Grid item>
            <Typography>
                Pay Period: {payStub.payStub.periodStart} - {payStub.payStub.periodEnd}
            </Typography>
        </Grid>
        <Grid item>
            <Typography>
                Pay Date: {payStub.payDate}
            </Typography>

        </Grid>
    </Grid>
}
export default PreviousPayStubDisplay;