import React from "react";
import {Box} from "@mui/material";

type PropsType = {
    logoUrl: string | undefined | null;
    logoWidth: string | undefined | null;
    logoHeight: string | undefined | null;
};

const LogoDisplay = (props: PropsType) => {
    const {logoUrl, logoWidth, logoHeight} = props;

    if (!logoUrl) {
        return null;
    }

    const width = logoWidth || '50px';
    const height = logoHeight || '50px';

    return (
        <Box
            component="img"
            src={logoUrl}
            alt="Logo"
            sx={{
                width: width,
                height: height,
                objectFit: 'contain',
                maxWidth: {xs: '100%', lg: '90%'},
                maxHeight: {xs: '100%', lg: '90%'},
            }}
        />
    );
};

export default LogoDisplay;