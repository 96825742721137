import {gql} from "@apollo/client";

const GET_DEDUCTION_TYPES = gql`

    query getDeductionTypes($anonUserId: String!) {
        getDeductionTypes(anonUserId: $anonUserId) {
            id
            label
            taxTreatment {
                code
                label
                description
            }
            displayOrder
        }
    }`;

export default GET_DEDUCTION_TYPES;