import React, {useCallback, useEffect, useState} from "react";
import LoadingErrorDisplay from "../common/LoadingErrorDisplay";

type UwConfig = {
    cloudName: string;
    uploadPreset: string;
}

type PropsType = {
    uwConfig: UwConfig;
    setPublicId: (publicId: string) => void;
    uploadError: (error: string) => void;
    close: () => void;
    open: boolean;
}
type UploadCallback = (error: any, result: any) => void;

declare global {
    interface Window {
        cloudinary: {
            createUploadWidget: (config: UwConfig, callback: UploadCallback) => any;
        };
    }
}

const CloudinaryUploadWidget = (props: PropsType) => {
    const {uwConfig, setPublicId, uploadError, close, open} = props;
    const [widget, setWidget] = useState<any>();

    const createWidget = useCallback(() => {
        return window.cloudinary.createUploadWidget(
            uwConfig,
            (error: any, result: any) => {
                if (!!error) {
                    console.error(error);
                    uploadError(error);
                    return;
                }
                if (result && result.event === "success") {
                    setPublicId(result.info.public_id);
                    close();
                } else if (result && result.event === "close") {
                    close();
                } else {
                    // do nothing. Lots of events are sent that we want to ignore.
                }
            }
        );
    }, [uwConfig, uploadError, setPublicId, close]);


    useEffect(() => {
        if (!widget) {
            setWidget(createWidget());
        }
    }, [widget, createWidget]);


    useEffect(() => {
        if (!widget) {
            return;
        }
        if (open) {
            widget.open();
        } else {
            widget.close();
        }
    }, [open, widget]);
    return <LoadingErrorDisplay
        loading={true}
    />;
}

export default CloudinaryUploadWidget;
