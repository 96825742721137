import {makeStyles} from '@mui/styles';
import React, {useState} from "react";
import {Card, CardContent, CardHeader, Grid, Tooltip, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {PayPeriod} from "../../../graphql/generated/graphql";
import RoePayPeriod from "./RoePayPeriod";


const useStyles = makeStyles(() => ({
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '0.9em',
        flexGrow: 1,
        paddingTop: 0
    },
    amount: {
        fontSize: '1.2em',
        textAlign: 'right',
        alignSelf: 'flex-end',
        paddingTop: 10
    },
    dates: {
        fontSize: '0.8em',
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        height: 110,
        width: 200,
    },
    cardHeader: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

type PropsType = {
    payPeriod: PayPeriod;
    payPeriodDeleted: (id: string) => void;
    payPeriodUpdated: (payPeriod: PayPeriod) => void;
}

const RoePayPeriodDisplayEdit = (props: PropsType) => {

    const {payPeriod, payPeriodDeleted, payPeriodUpdated} = props;

    const [editPayPeriod, setEditPayPeriod] = useState<PayPeriod>();
    const classes = useStyles();
    return <Grid container direction="row" alignItems="center">
        <Grid xs={12}>
            <Card
                className={classes.card}
            >
                <CardHeader
                    className={classes.cardHeader}
                    action={
                        <>
                            <Tooltip title="Delete pay period" placement="right" sx={{mr: 1}}>
                                <DeleteIcon onClick={() => payPeriodDeleted(payPeriod.id)}/>
                            </Tooltip>
                            <Tooltip title="Edit pay period" placement="right">
                                <EditIcon onClick={() => setEditPayPeriod(payPeriod)}/>
                            </Tooltip>
                        </>
                    }
                    title={`Pay period: ${payPeriod.number}`}
                />
                <CardContent className={classes.cardContent}>
                    <Typography className={classes.dates} color="textSecondary" component="p">
                        {payPeriod.startDate} to {payPeriod.endDate}
                    </Typography>
                    <Typography className={classes.amount} color="textSecondary"
                                component="p">
                        {payPeriod.amount}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
        {!!editPayPeriod &&
            <Grid item xs={12}>
                <RoePayPeriod
                    payPeriod={editPayPeriod}
                    updatePayPeriod={payPeriodUpdated}
                    close={() => {
                        setEditPayPeriod(undefined);
                    }}
                />
            </Grid>
        }
    </Grid>

}

export default RoePayPeriodDisplayEdit;