import {ClaimCode, useGetClaimCodesQuery} from "../graphql/generated/graphql";
import {ApolloError} from "@apollo/client";

type ReturnType = {
    claimCodes: Array<ClaimCode> | undefined;
    loading: boolean;
    error: ApolloError | undefined;
}
const useGetClaimCodes = (): ReturnType => {

    const {
        data,
        loading,
        error
    } = useGetClaimCodesQuery();

    return {
        claimCodes: data?.getClaimCodes,
        loading,
        error
    }
}

export default useGetClaimCodes;