import CloseIcon from "@mui/icons-material/Close";
import {IconButton} from "@mui/material";
import React from "react";

type PropsType = {

    close: () => void;
}

const CloseDialogButton = (props: PropsType) => {
    const {close} = props;
    return <IconButton
        edge="end"
        color="inherit"
        onClick={close}
        aria-label="close"
        sx={{
            position: 'absolute',
            top: 1,
            right: 15,
        }}
    >
        <CloseIcon fontSize={"large"}/>
    </IconButton>
}
export default CloseDialogButton;