import {ApolloError} from "@apollo/client";

type ReturnType = {
    getApolloErrors: (apolloError: ApolloError | undefined) => string[];
}

const useGetApolloErrors = (): ReturnType => {

    const getApolloErrors = (apolloError: ApolloError | undefined) => {
        const graphErrors = !!apolloError ? apolloError.graphQLErrors.map(graphqlError => graphqlError.message) : undefined;
        const clientErrors = !!apolloError ? apolloError.clientErrors.map(graphqlError => graphqlError.message) : undefined;
        const networkError = !!apolloError ? apolloError.networkError?.message : undefined;

        const allErrorStrings: Array<string> = [];

        if (!!networkError) {
            allErrorStrings.push(networkError);
        }
        if (!!clientErrors && clientErrors.length > 0) {
            allErrorStrings.push(...clientErrors);
        }
        if (!!graphErrors && graphErrors.length > 0) {
            allErrorStrings.push(...graphErrors);
        }
        return allErrorStrings;
    }

    return {
        getApolloErrors
    }
}

export default useGetApolloErrors;