import {PayFrequency, useGetPayFrequenciesQuery} from "../graphql/generated/graphql";
import {ApolloError} from "@apollo/client";

type ReturnType = {
    payFrequencies: Array<PayFrequency> | undefined;
    loading: boolean;
    error: ApolloError | undefined;
}
const useGetPayFrequencies = (): ReturnType => {

    const {
        data,
        loading,
        error
    } = useGetPayFrequenciesQuery();

    return {
        payFrequencies: data?.getPayFrequencies,
        loading,
        error
    }
}

export default useGetPayFrequencies;