import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import OneTwoPayTooltip from "./OneTwoPayTooltip";

type PropsType = {
    infoText: string;
    title: string;
    moreInfoLink?: string;
};

const InfoIconDialog = (props: PropsType) => {
    const {title, infoText, moreInfoLink} = props;
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function getDialogContent() {
        return (
            <>
                {infoText}
                {moreInfoLink && (
                    <>
                        {" "}Please see{" "}
                        <a target='_blank' rel="noreferrer" href={moreInfoLink}>
                            {moreInfoLink}
                        </a>{" "}
                        for more information.
                    </>
                )}
            </>
        );
    }

    return (
        <div>
            <OneTwoPayTooltip
                tipContent={"Click for more info"}
                onClick={handleClickOpen}
            />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {getDialogContent()}
                </DialogContent>
            </Dialog>
        </div>
    );
}
export default InfoIconDialog;