import React from "react";
import {FieldErrors, UseFormRegister} from "react-hook-form";
import {Grid, TextField} from "@mui/material";
import {FIELD_ARRAY_NAME, FillableParam, FormValues} from "./ReportParamterFormField";
import OneTwoPayTooltip from "../../../common/OneTwoPayTooltip";


type PropsType<T extends FillableParam> = {
    field: T & Record<"id", string>;
    errors: FieldErrors<FormValues>;
    required: boolean;
    register: UseFormRegister<FormValues>;
    index: number;
}

const ReportParameterFormTextField = <T extends FillableParam>(props: PropsType<T>) => {


    const {
        field,
        errors,
        register,
        required,
        index
    } = props;

    let options = undefined;
    if (required) {
        options = {required: `${field.name} is required`};
    }
    const error = !!errors.params ? errors.params[index] : undefined
    const errorMessage = !!error ? error.value?.message : undefined;
    return <Grid container>
        <Grid item xs={6} md={4}>
            <TextField
                key={field.id}
                fullWidth
                label={field.name}
                {...register(`${FIELD_ARRAY_NAME}.${index}.value`, options)}
                error={!!error}
                helperText={errorMessage}
            />
        </Grid>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <OneTwoPayTooltip
                tipContent={field.description}
            />


        </div>
    </Grid>;
}

export default ReportParameterFormTextField;