import React, {useEffect, useState} from "react";
import EmployeeSelectEdit from "./EmployeeSelectEdit";
import {Employee, GetEmployeeQuery, useGetEmployeeLazyQuery} from "../../graphql/generated/graphql";
import EmployeeDisplay from "./EmployeeDisplay";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import {Button} from "@mui/material";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import useAnonCookie from "../../security/useAnonCookie";
import usePayStubIdManager from "../PayStub/usePayStubIdManager";
import useSystemNotices from "../../Utils/useSystemNotices";


type PropsType = {
    employeeId: number | undefined | null;
    employeeAddedEdited: (employee: Employee) => void;
    employeeSelected: (employee: Employee) => void;
    isEditable: boolean;
}

function newEmployeeDataNeeded(employeeId: number | undefined | null, employeeData: GetEmployeeQuery | undefined) {
    const employeeIdExists = !!employeeId && Number(employeeId) > 0;
    const employeeDataCurrent = !!employeeData && employeeData.getEmployee.employee.id === employeeId;
    return employeeIdExists || !employeeDataCurrent;
}

const EmployeeDisplayEdit = (props: PropsType) => {

    const {employeeId, employeeAddedEdited, employeeSelected, isEditable} = props;
    const [editMode, setEditMode] = useState(false);
    const {getAnonUserId} = useAnonCookie();
    const {getPayStubId} = usePayStubIdManager();

    const {sendNotice} = useSystemNotices();
    const [
        getEmployee,
        {
            data: employeeData,
            loading: employeeLoading,
            error: employeeError
        }
    ] = useGetEmployeeLazyQuery();

    // todo: Kenny. Set a welcome message into a useState inside this useEffect.
    useEffect(() => {
        if (!employeeId) {
            setEditMode(true);
        }
    }, [employeeId, setEditMode]);

    useEffect(() => {
        if (!employeeId) {
            return;
        }
        if (newEmployeeDataNeeded(employeeId, employeeData) && !employeeLoading && !employeeError) {
            let anonUserId = getAnonUserId();
            getEmployee({
                variables: {
                    employeeId: employeeId,
                    currentPayStubId: getPayStubId(),
                    anonUserId: anonUserId
                }
            })
                .then(() => {/*do nothing. response captured in employeeData object */
                });
        }
    }, [employeeId, getEmployee, employeeData, employeeLoading, employeeError, getAnonUserId, getPayStubId]);

    let employeeWithHasPayStubsStatus = employeeData?.getEmployee;
    if (editMode) {
        // todo: Kenny, pass in the useState welcome message here.
        return <EmployeeSelectEdit
            close={() => setEditMode(false)}
            employee={employeeWithHasPayStubsStatus?.employee}
            employeeAddedEdited={employeeAddedEdited}
            employeeSelected={employeeSelected}
            hasPayStubs={!!employeeWithHasPayStubsStatus && employeeWithHasPayStubsStatus.hasPayStubs}
            isEditable={isEditable}
        />;
    }

    const noEmployeeDisplay: ReactJSXElement =
        <Button
            type={"button"}
            variant="contained"
            size="large"
            onClick={() => setEditMode(true)}
        >
            Add employee
        </Button>;

    if (!!employeeError) {
        sendNotice(`Error getting employee in EmployeeDisplayEdit: ${employeeError.message}`);
    }

    if (employeeLoading || employeeError) {
        return <LoadingErrorDisplay
            apolloError={employeeError}
            loading={employeeLoading}
        />
    }

    return <EmployeeDisplay
        isEditable={isEditable}
        employee={employeeWithHasPayStubsStatus?.employee}
        edit={() => setEditMode(true)}
        noEmployeeDisplay={noEmployeeDisplay}
    />
}

export default EmployeeDisplayEdit;