import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Button, Grid, TextField, Typography} from "@mui/material";
import {SendTrustPilotInvitationMutation, useSendTrustPilotInvitationMutation} from "../graphql/generated/graphql";
import LoadingErrorDisplay from "../common/LoadingErrorDisplay";
import {FetchResult} from "@apollo/client";


type InvitationFormType = {
    userEmail: string
}

function getSendResultMessage(result: FetchResult<SendTrustPilotInvitationMutation>) {
    let data = result.data;
    if (data && data.sendTrustPilotInvitation.sent) {
        return "Invitation sent successfully";
    }
    return "";
}

function getResultErrorMessageOrBlank(result: FetchResult<SendTrustPilotInvitationMutation>) {
    let data = result.data;
    if (!data) {
        return "";
    }
    return data.sendTrustPilotInvitation.error ? data.sendTrustPilotInvitation.error : "";
}

const TrustPilotInvite = () => {

    const [sendResult, setSendResult] = useState("");
    const [sendError, setSendError] = useState("");

    const [
        sendTrustPilotInvitation,
        {
            error,
            loading
        }
    ] = useSendTrustPilotInvitationMutation();


    const {
        handleSubmit,
        register,
        formState: {errors},
    } = useForm<InvitationFormType>();

    const sendInvitation = (data: InvitationFormType) => {
        sendTrustPilotInvitation({
            variables: {
                userEmail: data.userEmail
            }
        })
            .then(result => {
                setSendResult(getSendResultMessage(result));
                setSendError(getResultErrorMessageOrBlank(result));
            })
            .catch(() => {
                // do nothing error handled using the error property
            });
    }
    return <form noValidate onSubmit={handleSubmit(sendInvitation)}>
        <Grid container spacing={1} justifyContent={"start"} sx={{mt: 1.5, ml: 4}}>
            <Grid item xs={12}>
                <Typography
                    variant="h1"
                    // align="center"
                    sx={{fontSize: '2.4rem', mb: 5}}
                >
                    Send an invitation to a user to review OneTwoPay on TrustPilot
                </Typography>
            </Grid>
            <Grid item xs={6} sx={{mt: 3}}>
                <TextField
                    InputProps={{style: {fontSize: "1.7rem"}}}
                    fullWidth
                    label={"User email"}
                    InputLabelProps={{style: {fontSize: "1.2rem"}}}
                    {...register("userEmail", {required: "User email is required"})}
                    error={!!errors.userEmail}
                    helperText={errors.userEmail?.message}
                />
            </Grid>
            <Grid xs={12}>
                {
                    (loading || !!error || !!sendError) &&
                    <LoadingErrorDisplay
                        loading={loading}
                        apolloError={error}
                        stringError={sendError}
                    />
                }
            </Grid>
            <Grid xs={12} sx={{mt: 3}}>
                <Button
                    type={"submit"}
                    variant="contained"
                    size="large"
                    disabled={loading}
                >
                    Send invitation
                </Button>
            </Grid>
            <Grid xs={12} sx={{mt: 3}}>
                <Typography
                    sx={{ fontSize: '1.5rem', color: 'green'}}
                >
                    {sendResult}
                </Typography>
            </Grid>

        </Grid>
    </form>
}
export default TrustPilotInvite;