import {IncomeType} from "../../graphql/generated/graphql";


export const getIncomeType = (incomeTypeId: string | undefined, incomeTypes: Array<IncomeType> | undefined): IncomeType | undefined => {
    if (!incomeTypeId || !incomeTypes) {
        return undefined;
    }
    return incomeTypes.find(incomeType => incomeType.id === incomeTypeId);
}

function getRateLabelOrDefault(incomeType: IncomeType | undefined) {
    return !!incomeType ? incomeType.rateLabel : "Hourly";
}

function getUnitsLabelOrDefault(incomeType: IncomeType | undefined) {
    return !!incomeType ? incomeType.unitsLabel : "Hrs";
}

export const getRateLabel = (incomeTypeId: string | undefined, incomeTypes: Array<IncomeType> | undefined) => {
    return getRateLabelOrDefault(getIncomeType(incomeTypeId, incomeTypes));
}
export const getUnitsLabel = (incomeTypeId: string | undefined, incomeTypes: Array<IncomeType> | undefined) => {
    return getUnitsLabelOrDefault(getIncomeType(incomeTypeId, incomeTypes));
}

