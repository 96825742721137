export function multiply(value1?: string | null, value2?: string | null): string {
    if (!value1 || !value2) {
        return "";
    }
    return (getNumber(value1) * getNumber(value2)).toFixed(2);
}

export function subtractStrings(value1?: string | null, value2?: string | null): string {
    if (!value1 || !value2) {
        return "";
    }
    return (getNumber(value1) - getNumber(value2)).toFixed(2);
}
export function add(value1?: string | null, value2?: string | null): string {
    return (getNumber(value1) + getNumber(value2)).toFixed(2);
}

export function getNumber(amount: number | string | undefined | null) {
    if (!amount) {
        return 0;
    }
    let amountAsNumber = amount;
    if (typeof amountAsNumber === 'string') {
        amountAsNumber = parseFloat(amountAsNumber.replace(/[^0-9.-]+/g, ""));
    }
    return amountAsNumber;
}
