import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import {Box, Grid, Typography} from "@mui/material";
import {Employee} from "../../graphql/generated/graphql";
import OneTwoPayTooltip from "../../common/OneTwoPayTooltip";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";

type PropsType = {
    employee?: Employee;
    edit: () => void;
    noEmployeeDisplay: ReactJSXElement;
    isEditable: boolean;
}

const EmployeeDisplay = (props: PropsType) => {
    const {employee, noEmployeeDisplay, edit, isEditable} = props;

    const gridStyle = {
        // minWidth: "600px",
        "@media (max-width: 960px)": {minWidth: "auto",},
        "@media (min-width: 961px) and (max-width: 1400px)": {minWidth: "auto",},
        maxWidth: "100%",
        // overflow: "hidden",
        // whiteSpace: "nowrap",
        // textOverflow: "ellipsis",
    };

    if (!employee || employee.id < 1) {
        return noEmployeeDisplay;
    }

    return <Grid display="flex" alignItems="center" sx={gridStyle}>
        <Grid item xs={3.5} />
        <Grid item xs={8.5} justifyContent={{xs: "flex-start", sm: "flex-end"}}>
            <Box ml={2} textAlign={{xs: "left", sm: "right"}}>
                <Typography variant={'h4'}>
                    {employee.firstName} {employee.lastName}
                    {isEditable &&
                        <OneTwoPayTooltip tipContent={"Edit employee"}>
                            <EditIcon
                                color={"primary"}
                                sx={{ml: 2}}
                                onClick={() => edit()}
                            />
                        </OneTwoPayTooltip>
                    }
                </Typography>
                <Typography variant={"body1"}>Employee Id: {employee.companyUserId}</Typography>
                <Typography variant={'body1'}>{employee.address1}</Typography>
                <Typography variant={'body1'}>{employee.address2}</Typography>
                <Typography variant={'body1'}>
                    {employee.city}, {employee.province}, {employee.postalCode?.toUpperCase()}
                </Typography>
            </Box>
        </Grid>
    </Grid>
};

export default EmployeeDisplay;