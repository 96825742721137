import {TableCell, TableRow, Typography} from "@mui/material";
import React from "react";

import {Deduction} from "../../graphql/generated/graphql";
import {add, subtractStrings} from "../../Utils/stringMath";
import {DeductionWithType} from "./useGetDeductionTypeInfo";
import OneTwoPayNumericTextField, {NumericValueUpdateEvent} from "../../common/OneTwoPayNumericTextField";


type PropsType = {
    isTaxEditable: boolean;
    saveDeduction: (deduction: Deduction) => void;
    deductionWithType: DeductionWithType
}

const StyledDeductionsTableCell = ({children}: {
    children: React.ReactNode
}) => {
    return <TableCell>
        <Typography variant="h6" sx={{fontWeight: "400", fontSize: '1.3rem'}}>
            {children}
        </Typography>
    </TableCell>
}

const EditableCoreDeductionLine = (props: PropsType) => {

    const {
        isTaxEditable,
        deductionWithType,
        saveDeduction
    } = props;

    const deduction = deductionWithType.deduction;

    const updateCurrentPeriodAmount = (currentPeriodAmount: NumericValueUpdateEvent) => {
        saveDeduction({
            ...deductionWithType.deduction,
            amount: currentPeriodAmount.target.value,
            ytdAmount: add(currentPeriodAmount.target.value, deductionWithType.deduction.priorPeriodAmount)
        });
    }

    const updateYtdAmount = (ytdAmount: NumericValueUpdateEvent) => {
        saveDeduction({
            ...deductionWithType.deduction,
            ytdAmount: ytdAmount.target.value,
            priorPeriodAmount: subtractStrings(ytdAmount.target.value, deductionWithType.deduction.amount)
        });
    }

    return <TableRow key={deduction.id}>
        <StyledDeductionsTableCell>{deductionWithType.deductionType.label}</StyledDeductionsTableCell>
        <TableCell>
            {isTaxEditable ?
                <OneTwoPayNumericTextField
                    amount={deduction.amount}
                    onChange={updateCurrentPeriodAmount}
                />
                :
                <Typography sx={{fontSize: '1.4rem'}}>
                    {deduction.amount}
                </Typography>
            }
        </TableCell>
        <TableCell>
            {isTaxEditable ? <>
                    <OneTwoPayNumericTextField
                        amount={deduction.ytdAmount}
                        onChange={updateYtdAmount}
                    />
                </> :
                <Typography sx={{fontSize: '1.4rem'}}>
                    {deduction.ytdAmount}
                </Typography>
            }
        </TableCell>
    </TableRow>
}

export default EditableCoreDeductionLine;