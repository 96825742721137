import {NumericFormat} from "react-number-format";
import {TextField} from "@mui/material";
import React, {forwardRef, useState} from "react";

export type NumericValueUpdateEvent = {
    target: {
        name: string;
        value: string;
    };
}

type PropsType = {
    amount: string;
    onChange: (event: NumericValueUpdateEvent, type?: any) => void;
    name?: string;
    label?: string;
    error?: boolean;
    errorText?: string | undefined;
    variant?: "standard" | "outlined";
    sx?: object;
    prefix?: string | false;
}

const OneTwoPayNumericTextField = forwardRef<HTMLInputElement, PropsType>((props, ref) => {
    const {label, name, amount, onChange, error, errorText, variant = "standard", sx, prefix = "$"} = props; // Default to "$" for prefix

    const [hasFocus, setHasFocus] = useState(false);

    const getBlankIfZero = (value: string) => {
        let formattedValue = value.replace(/[^0-9.]/g, '');
        return parseFloat(formattedValue) === 0 ? '' : value;
    }

    const blankForZeroAmount = hasFocus ? getBlankIfZero(amount) : amount;

    return (
        <NumericFormat
            label={label || ""}
            customInput={TextField}
            variant={variant}
            value={blankForZeroAmount}
            onValueChange={(values) => {
                const {value} = values;
                onChange({target: {name: name || 'name', value: value}});
            }}
            fullWidth
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
            sx={{...sx}}
            thousandSeparator
            error={error}
            helperText={errorText}
            prefix={prefix || undefined}
            decimalScale={2}
            fixedDecimalScale
            allowNegative={false}
            inputRef={ref}
        />
    );
});

export default OneTwoPayNumericTextField;
