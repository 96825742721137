import React from "react";
// import {DatePicker} from "@mui/x-date-pickers";
import {MobileDatePicker} from "@mui/x-date-pickers";
import {DATE_FORMAT, getDateFromString, getStringFromNullableDate} from "../../Utils/dateFormatter";
import {useSavePayDateMutation} from "../../graphql/generated/graphql";
import usePayStubIdManager from "./usePayStubIdManager";
import {ApolloError} from "@apollo/client";
import useAnonCookie from "../../security/useAnonCookie";

type PropsType = {
    isEditable: boolean;
    date: string
    savePayDate: (date: string, payStubId: number) => void;
    setApolloError: (error: ApolloError) => void;
    setStringError: (error: string) => void;
    clearErrors: () => void;
}
const PayDateDisplayEdit = (props: PropsType) => {

    const {
        isEditable,
        date,
        savePayDate: savePayDateLocally,
        setStringError,
        setApolloError,
        clearErrors
    } = props;

    const {getAnonUserId} = useAnonCookie();
    const {
        getPayStubId
    } = usePayStubIdManager();

    const [
        savePayDateToServer
    ] = useSavePayDateMutation();

    const setDate = (payDate: string | undefined) => {
        clearErrors();
        if (!payDate) {
            return;
        }
        savePayDateToServer({
            variables: {
                input: {
                    payDate: payDate,
                    payStubId: getPayStubId(),
                },
                anonUserId: getAnonUserId()
            }
        })
            .then(result => {
                const resultData = result.data?.savePayDate;
                if (!!resultData && resultData.successful) {
                    savePayDateLocally(resultData.payDate, resultData.payStubId);
                    return;
                }
                if (!!resultData && resultData.message) {
                    setStringError(resultData.message);
                }
            })
            .catch(error => setApolloError(error));
    }

    return <MobileDatePicker
        label="Pay Date"
        value={getDateFromString(date)}
        onChange={(date) => setDate(getStringFromNullableDate(date, DATE_FORMAT))}
        disabled={!isEditable}
        sx={{
            '& .MuiInputBase-root': {px: '3px'},
            maxWidth: {xs: '110px', sm: '140px'},
        }}
    />
}

export default PayDateDisplayEdit;