import React, {ReactNode} from 'react';
import {AppState, Auth0Provider} from '@auth0/auth0-react';
import {useNavigate} from 'react-router-dom';

type PropsType = {
    children: ReactNode;
}

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN || "onetwopay-dev.us.auth0.com";
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID || "q8K6ZQA9hSXrpWFkwG8X2rgjsRaAVfx5";
// const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;

const One2PayAuthProvider = (props: PropsType) => {
    const navigate = useNavigate();
    const redirectUri = process.env.REACT_APP_AUTH0_REDIRECT_URL;
    const onRedirectCallback = (appState:AppState | undefined) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    return <Auth0Provider
        domain={auth0Domain}
        clientId={auth0ClientId}
        useRefreshTokens={true}
        // setting useRefreshTokensFallback to true tells the auth0 SDK to use an iFrame to restore the session silently if a refresh token is not available.
        // Note that this relies on third-party cookies (unless using a custom domain) which browsers are blocking more and more.
        // So, in the dev environment where we don't use a custom domain, we will see the "missing refresh token" error in situations where it works fine in prod because we have a custom domain.
        // see the following issue for more details. https://community.auth0.com/t/auth0-spa-2-x-returning-missing-refresh-token/98999/30
        useRefreshTokensFallback={true}
        authorizationParams={{
            redirect_uri: redirectUri,
            audience: "onetwopay-apis",
            scope: "openid profile email read:pay_stub read:employee read:employer read:customer read:deduction_type read:income_type write:trust_pilot"
        }}
        onRedirectCallback={onRedirectCallback}
    >
        {props.children}
    </Auth0Provider>
}

export default One2PayAuthProvider;