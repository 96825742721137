import {useCallback, useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import useSystemNotices from "./useSystemNotices";
import {useLocation} from "react-router-dom";


type ReturnType = {
    setDomainCookie: (value: Array<string> | string, expiryDate: Date) => void;
    getDomainCookie: () => Array<string> | string | undefined;
}

function useCookieManager(cookieKey: string): ReturnType {

    const [
        cookies,
        setCookie
    ] = useCookies([cookieKey]);

    const {sendNotice} = useSystemNotices();
    const location = useLocation();

    const [cookieValueSet, setCookieValueSet] = useState<Array<string> | string>();


    const getDomainCookie = useCallback(() => {
        return cookies[cookieKey];
    }, [cookies, cookieKey]);

    const setDomainCookie = useCallback((value: Array<string> | string, expiryDate: Date) => {
        setCookieValueSet(undefined);
        let isLocalHost = window.location.hostname.includes("localhost");
        let options = {
            path: "/",
            domain: isLocalHost ? undefined : ".onetwopay.ca",
            expires: expiryDate
        };
        setCookie(cookieKey, value, options);
        setCookieValueSet(value);
    }, [setCookie, cookieKey]);

    useEffect(() => { // this has to be in a useEffect because setCookie relies on reacts asynchronous update of state.
        const retrievedValue = cookies[cookieKey];
        if (!!cookieValueSet && (retrievedValue !== cookieValueSet)) {
            console.error(`could not set the cookie value: ${cookieValueSet}`);
            sendNotice(`Expected cookie is not set: ${cookieKey} with value: ${cookieValueSet} in Url: ${location.pathname}. Retrieved value: ${retrievedValue}`);
            // if we start getting these notices, need to add the expiry date to the message. It might be that the cookie is expiring, which is ok and desired. The anonUserIdupdate provider will set a new one.
        }
    }, [cookieValueSet, cookies, sendNotice, cookieKey, location.pathname]);

    return {
        setDomainCookie,
        getDomainCookie
    }
}

export default useCookieManager;