import {IncomeItem, IncomeType} from "../../graphql/generated/graphql";
import React from "react";
import {Grid, TableCell, Typography} from "@mui/material";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import {getCurrencyFormatFromNullableStringOrNumber} from "../../Utils/currencyFormatter";

type PropsType = {
    incomeItem: IncomeItem,
    incomeType: IncomeType | undefined,
    editIconDialog?: JSX.Element,
    isRateBased: boolean
}

function getIncomeTypeDisplay(incomeType: IncomeType | undefined) {
    if (!incomeType) {
        return <LoadingErrorDisplay
            loading={true}
        />
    }
    return <Typography sx={{mr: 2}}>
        {incomeType.label}
    </Typography>;
}

const IncomeItemDisplay = (props: PropsType) => {
    const {incomeItem, incomeType, editIconDialog, isRateBased} = props;
    return <>
        <TableCell >
            <Grid display="flex" alignItems="center" >
                {getIncomeTypeDisplay(incomeType)}
                {editIconDialog}
            </Grid>

            {isRateBased &&
                <Typography
                    sx={{fontSize: '1.2rem'}}
                    data-testid={"income-item-display-units"}
                >
                    {incomeItem.units} @ {getCurrencyFormatFromNullableStringOrNumber(incomeItem.rate)}
                </Typography>
            }
        </TableCell>
        <TableCell sx={{verticalAlign: 'bottom'}}>
            <Typography sx={{fontSize: '1.4rem'}}>
                {getCurrencyFormatFromNullableStringOrNumber(incomeItem.amount)}
            </Typography>
        </TableCell>
        <TableCell sx={{verticalAlign: 'bottom'}}>
            <Typography sx={{fontSize: '1.4rem'}}>
                {getCurrencyFormatFromNullableStringOrNumber(incomeItem.ytdAmount)}
            </Typography>
        </TableCell>
    </>
}
export default IncomeItemDisplay;