import {Button} from "@mui/material";
import React, {useState} from "react";
import {Employer, useGetEmployersQuery} from "../../graphql/generated/graphql";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import EmployerSelectEdit from "./EmployerSelectEdit";
import useAnonCookie from "../../security/useAnonCookie";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import EmployerDisplayWithLogo from "./EmployerDisplayWithLogo";
import {getNumber} from "../../Utils/stringMath";

function getEmployer(employers: Array<Employer> | undefined, employerId: string | null | undefined): Employer | undefined {
    if (!employers || employers.length === 0) {
        return undefined;
    }
    if (employers.length === 1 && !getNumber(employerId)) {
        return employers[0];
    }
    if (!employerId) {
        return undefined;
    }
    return employers.find(employer => employer.id.toString() === employerId);
}

type PropsType = {
    employerId: string | undefined | null;
    employerUpdated: (employerId: string) => void;
    isEditable: boolean;
    clearEmployer: () => void;
}

const EmployerDisplayEdit = (props: PropsType) => {
    const {employerId, employerUpdated, isEditable, clearEmployer} = props;
    const [editMode, setEditMode] = useState(false);
    const {getAnonUserId} = useAnonCookie();

    const {
        data: employersData,
        loading: employersLoading,
        error: employersError
    } = useGetEmployersQuery({variables: {anonUserId: getAnonUserId()}});

    let employers = employersData?.getEmployers;
    let employer = getEmployer(employers, employerId);
    if (editMode && !!employers) {
        return <EmployerSelectEdit
            close={() => setEditMode(false)}
            employer={employer}
            employerUpdated={employerUpdated}
            employers={employers}
            isEditable={isEditable}
            clearEmployer={clearEmployer}
        />;
    }
    const noEmployerDisplay: ReactJSXElement = <Button
        type={"button"}
        variant="contained"
        size="large"
        onClick={() => setEditMode(true)}
    >
        Add employer
    </Button>;

    if (employersLoading || !!employersError) {
        return <LoadingErrorDisplay
            loading={employersLoading}
            apolloError={employersError}
        />
    }

    return <EmployerDisplayWithLogo
        employer={employer}
        edit={() => setEditMode(true)}
        noEmployerDisplay={noEmployerDisplay}
        employerUpdated={employerUpdated}
        isEditable={isEditable}
    />;
}
export default EmployerDisplayEdit;