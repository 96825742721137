import {ApolloError} from "@apollo/client";
import React from "react";
import {Typography} from "@mui/material";
import useGetApolloErrors from "../Utils/useGetApolloErrors";

type PropsType = {
    apolloError?: ApolloError | undefined;
    stringError?: string | Array<string> | undefined | null;
}

const ErrorDisplay = (props: PropsType) => {
    const {apolloError, stringError} = props;

    const {getApolloErrors} = useGetApolloErrors();

    let allErrorStrings = getApolloErrors(apolloError);

    if(!!stringError && typeof stringError === "string"){
        allErrorStrings.push(stringError);
    }
    if (!!stringError && Array.isArray(stringError) && stringError.length > 0) {
        stringError.forEach(error => allErrorStrings.push(error));
    }

    return <Typography
        component={'span'}
        variant="body2"
        color="error"
        fontSize={"1.5rem"}
    >
        {allErrorStrings.map((item, index) => (
            <p key={index}>{`• ${item}`}</p>
        ))}
    </Typography>
}
export default ErrorDisplay;