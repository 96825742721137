import {Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import EditEmployerForm from "./EditEmployerForm";
import {Employer} from "../../graphql/generated/graphql";

type PropsType = {
    close: () => void;
    employer: Employer;
}
const EditEmployerDialog = (props: PropsType) => {

    const {close, employer} = props;

    return <Dialog open={true}
                   sx={{
                       mt: 5,
                       '& .MuiDialog-paper': {
                           minWidth: {xs: '95vw', sm: '80vw', md: '65vw', lg: '90vw'},
                           minHeight: '400px',
                       },
                   }}>
        <DialogTitle>
            <IconButton
                edge="end"
                color="inherit"
                onClick={close}
                aria-label="close"
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 12,
                }}
            >
                <CloseIcon/>
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <EditEmployerForm
                close={close}
                employer={employer}
                employerUpdated={() => {
                    close();
                }}
            />
        </DialogContent>
    </Dialog>;
}

export default EditEmployerDialog;