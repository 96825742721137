import {useSendSystemNoticeMutation} from "../graphql/generated/graphql";
import {useCallback, useState} from "react";
import useAnonCookie from "../security/useAnonCookie";


type ReturnType = {
    sendNotice: (message: string) => void;
}

const useSystemNotices = (): ReturnType => {

    const [
        sendSystemNotice,
    ] = useSendSystemNoticeMutation();

    const {getAnonUserId} = useAnonCookie();
    const [lastNoticeSent, setLastNoticeSent] = useState<string>(); // prevent sending duplicate notices and infinite sending of same notice.

    const sendNotice = useCallback(async (message: string): Promise<void> => {
        if (message === lastNoticeSent) {
            console.error(`Prevented sending same notice twice: ${message}.`);
            return;
        }
        try {
            const result = await sendSystemNotice({
                variables: {
                    message: message,
                    anonUserId: getAnonUserId()
                }
            });
            console.log(`sendNotice result: ${result.data?.sendSystemNotice.successful}`)
        } catch (exception) {
            console.error(exception);
        }
        setLastNoticeSent(message);
    }, [sendSystemNotice, lastNoticeSent, getAnonUserId]);

    return {
        sendNotice
    }
}

export default useSystemNotices;