import {Button, Checkbox, FormControlLabel, Grid, MenuItem, TextField} from "@mui/material";
import React, {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {Employee, EmployeeInput, useSaveEmployeeMutation} from "../../graphql/generated/graphql";
import useAnonCookie from "../../security/useAnonCookie";
import ReactHookFormSelect from "../../common/ReactHookFormSelect";
import useGetProvinces from "../../Utils/useGetProvinces";
import useGetPayFrequencies from "../../Utils/useGetPayFrequencies";
import useGetClaimCodes from "../../Utils/useGetClaimCodes";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import GET_EMPLOYEES from "../../graphql/queries/getEmployees";
import GET_EMPLOYEES_WITH_STATUS from "../../graphql/queries/getEmployeesWithStatus";
import {deleteFromApolloCache} from "../../graphql/ApolloClient";
import GET_EMPLOYEE from "../../graphql/queries/getEmployee";
import ReactFormSwitch from "../../common/ReactFormSwitch";
import useSystemNotices from "../../Utils/useSystemNotices";
import OneTwoPayNumericTextField from "../../common/OneTwoPayNumericTextField";

function getEmployeeInput(employee: Employee): EmployeeInput {
    return {
        id: employee.id,
        firstName: employee.firstName,
        lastName: employee.lastName,
        address1: employee.address1,
        address2: employee.address2,
        city: employee.city,
        companyUserId: employee.companyUserId,
        postalCode: employee.postalCode,
        province: employee.province,
        eiExempt: employee.eiExempt,
        cppExempt: employee.cppExempt,
        federalClaimCode: employee.federalClaimCode,
        provincialClaimCode: employee.provincialClaimCode,
        employerEiRatio: employee.employerEiRatio,
        payFrequency: employee.payFrequency,
        employmentProvince: employee.employmentProvince,
        sin: employee.sin,
        applyToExistingStubs: !!employee.id
    };
}

type PropsType = {
    close: () => void;
    employee: Employee;
    employeeAddedEdited: (employee: Employee) => void;
    hasPayStubs: boolean;
}

function getRefetchQueries(anonUserId: string, data: EmployeeInput) {
    if (!data || !data.id) {
        return [
            {
                query: GET_EMPLOYEES,
                variables: {
                    anonUserId: anonUserId,
                },
            },
            {
                query: GET_EMPLOYEES_WITH_STATUS,
            }
        ];
    }
    return [
        {
            query: GET_EMPLOYEES,
            variables: {
                anonUserId: anonUserId,
            },
        },
        {
            query: GET_EMPLOYEE,
            variables: {
                employeeId: data.id,
                anonUserId: anonUserId
            },
        },
        {
            query: GET_EMPLOYEES_WITH_STATUS,
        }
    ];
}

const EditEmployeeForm = (props: PropsType) => {

    const {close, employee, employeeAddedEdited, hasPayStubs} = props;
    const {
        register,
        handleSubmit,
        formState,
        control,
        watch
    } = useForm<EmployeeInput>({defaultValues: getEmployeeInput(employee)});
    const {errors} = formState;
    const {sendNotice} = useSystemNotices();

    const [saveErrors, setSaveErrors] = useState<string | Array<string>>();

    const {getAnonUserId} = useAnonCookie();
    const [
        saveEmployeeToServer,
        {
            loading: saveEmployeeLoading,
            error: saveEmployeeError
        }
    ] = useSaveEmployeeMutation();

    const {
        provinces,
        error: provincesError,
        loading: provincesLoading
    } = useGetProvinces();
    const {
        payFrequencies,
        error: payFrequenciesError,
        loading: payFrequenciesLoading
    } = useGetPayFrequencies();
    const {
        claimCodes,
        error: claimCodesError,
        loading: claimCodesLoading
    } = useGetClaimCodes();


    const saveEmployee = (data: EmployeeInput) => {
        if (!!data.id) {
            deleteFromApolloCache("EmployeeWithStatus", data.id);
            deleteFromApolloCache("Employee", data.id);
        }
        let anonUserId = getAnonUserId();
        saveEmployeeToServer({
            variables: {
                employee: data,
                anonUserId: anonUserId
            },
            refetchQueries: getRefetchQueries(anonUserId, data)
        })
            .then(saveResult => {
                const savedEmployee = saveResult.data?.saveEmployee;
                if (!!savedEmployee && savedEmployee.successful && savedEmployee.employee) {
                    employeeAddedEdited(savedEmployee.employee);
                    close();
                    return;
                }
                setSaveErrors(savedEmployee?.messages);
            })
            .catch(error => {
                sendNotice(`Error saving employee. Message: ${error.message}`)
                setSaveErrors(error);
            })
    }

    let eiRatioAmount = watch(`employerEiRatio`) || "1.4";


    return <form noValidate onSubmit={handleSubmit(saveEmployee)}>

        <Grid container alignItems="center" spacing={1.5}>
            <Grid container spacing={1} justifyContent={"start"} sx={{mt: 1.5, ml: .25}}>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={"Employee ID"}
                        {...register("companyUserId", {
                            maxLength: {
                                value: 30,
                                message: "Employee ID cannot exceed 30 characters"
                            },
                        })}
                        error={!!errors.companyUserId}
                        helperText={errors.companyUserId?.message}
                    />
                </Grid>

            </Grid>

            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label={"First Name"}
                    {...register("firstName", {required: "First name is required"})}
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label={"Last Name"}
                    {...register("lastName")}
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={"Address 1"}
                    {...register("address1")}
                    error={!!errors.address1}
                    helperText={errors.address1?.message}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={"Address 2"}
                    {...register("address2")}
                    error={!!errors.address2}
                    helperText={errors.address2?.message}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label={"City"}
                    {...register("city")}
                    error={!!errors.city}
                    helperText={errors.city?.message}
                />
            </Grid>
            <Grid item xs={4}>
                {!!provinces &&
                    <ReactHookFormSelect<EmployeeInput>
                        nameOfFormField={"province"}
                        label={"Province"}
                        control={control}
                    >
                        {provinces.map((province) => (
                            <MenuItem key={province.key} value={province.key}>
                                {province.label}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>
                }
                {(!!provincesError || errors.province?.message || provincesLoading) &&
                    <LoadingErrorDisplay
                        apolloError={provincesError}
                        stringError={errors.province?.message}
                        loading={provincesLoading}
                    />
                }
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label={"Postal Code"}
                    {...register("postalCode", {
                        pattern: {
                            value: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
                            message: "Invalid Canadian Postal Code format"
                        }
                    })}
                    error={!!errors.postalCode}
                    helperText={errors.postalCode?.message}
                />
            </Grid>
            <Grid item xs={8} sx={{ml: 1}}>
                <Controller
                    control={control}
                    name="eiExempt"
                    render={({field}) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={field.value || false}
                                    onChange={e => field.onChange(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label="EI exempt"
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="cppExempt"
                    render={({field}) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={field.value || false}
                                    onChange={e => field.onChange(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label="CPP exempt"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                {!!claimCodes &&
                    <ReactHookFormSelect<EmployeeInput>
                        nameOfFormField={"federalClaimCode"}
                        label={"Federal claim code"}
                        control={control}
                    >
                        {claimCodes.map((claimCode) => (
                            <MenuItem key={claimCode.key} value={claimCode.key}>
                                {claimCode.label}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>
                }
                {(!!claimCodesError || errors.federalClaimCode?.message || claimCodesLoading) &&
                    <LoadingErrorDisplay
                        loading={claimCodesLoading}
                        apolloError={claimCodesError}
                        stringError={errors.federalClaimCode?.message}
                    />
                }
            </Grid>
            <Grid item xs={6}>
                {!!claimCodes &&
                    <ReactHookFormSelect<EmployeeInput>
                        nameOfFormField={"provincialClaimCode"}
                        label={"Provincial claim code"}
                        control={control}
                    >
                        {claimCodes.map((claimCode) => (
                            <MenuItem key={claimCode.key} value={claimCode.key}>
                                {claimCode.label}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>
                }
                {(!!claimCodesError || errors.provincialClaimCode?.message || claimCodesLoading) &&
                    <LoadingErrorDisplay
                        loading={claimCodesLoading}
                        apolloError={claimCodesError}
                        stringError={errors.provincialClaimCode?.message}
                    />
                }
            </Grid>
            <Grid item xs={6}>
                <OneTwoPayNumericTextField
                    amount={eiRatioAmount}
                    label={"Employer EI Ratio"}
                    {...register("employerEiRatio")}
                    error={!!errors.employerEiRatio}
                    errorText={errors.employerEiRatio?.message}
                    variant={"outlined"}
                    prefix={false}
                />
            </Grid>
            <Grid item xs={6}>
                {!!payFrequencies &&
                    <ReactHookFormSelect<EmployeeInput>
                        nameOfFormField={"payFrequency"}
                        label={"Pay Frequency"}
                        control={control}
                    >
                        {payFrequencies.map((frequency) => (
                            <MenuItem key={frequency.key} value={frequency.key}>
                                {frequency.label}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>
                }
                {(!!payFrequenciesError || !!errors.payFrequency?.message || payFrequenciesLoading) &&
                    <LoadingErrorDisplay
                        loading={payFrequenciesLoading}
                        apolloError={payFrequenciesError}
                        stringError={errors.payFrequency?.message}
                    />
                }
            </Grid>
            <Grid item xs={6}>
                {!!provinces &&
                    <ReactHookFormSelect<EmployeeInput>
                        nameOfFormField={"employmentProvince"}
                        label={"Employment Province"}
                        control={control}
                    >
                        {provinces.map((province) => (
                            <MenuItem key={province.key} value={province.key}>
                                {province.label}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>
                }
                {(!!payFrequenciesError || errors.employmentProvince?.message || payFrequenciesLoading) &&
                    <LoadingErrorDisplay
                        apolloError={provincesError}
                        stringError={errors.employmentProvince?.message}
                        loading={provincesLoading}
                    />
                }

            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label={"Social Insurance Number"}
                    {
                        ...register(
                            "sin", {
                                pattern: {
                                    value: /^\d\d\d[ -]*\d\d\d[ -]*\d\d\d$/,
                                    message: "Must be exactly 9 numbers. No letters."
                                }

                            }
                        )
                    }
                    error={!!errors.sin}
                    helperText={errors.sin?.message}
                />
            </Grid>
            <Grid item xs={8} sm={6}>
                {hasPayStubs &&
                    <ReactFormSwitch
                        fieldName={'applyToExistingStubs'}
                        label={'Apply update to existing pay stubs'}
                        control={control}
                    />
                }
            </Grid>
            <Grid item xs={12}>
                <LoadingErrorDisplay
                    loading={saveEmployeeLoading}
                    apolloError={saveEmployeeError}
                    stringError={saveErrors}
                >
                    <Button
                        onClick={() => close()}
                        variant='contained'
                        color='error'
                        sx={{mr: 2}}
                    >
                        Cancel
                    </Button>
                    <Button
                        type={"submit"}
                        variant='contained'
                    >
                        Save
                    </Button>
                </LoadingErrorDisplay>
            </Grid>

        </Grid>
    </form>;
}

export default EditEmployeeForm;