import React, {useState} from "react";
import {IncomeType, useSaveIncomeTypeMutation} from "../../graphql/generated/graphql";
import {Button, Grid, MenuItem, TextField, Typography} from "@mui/material";
import {useForm} from "react-hook-form";
import ReactHookFormSelect from "../../common/ReactHookFormSelect";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import useAnonCookie from "../../security/useAnonCookie";
import GET_INCOME_TYPES from "../../graphql/queries/getIncomeTypes";
import useSystemNotices from "../../Utils/useSystemNotices";

type PropsType = {
    currentPayStubId: number | undefined;
    incomeType: IncomeType;
    setIncomeType: (incomeType: IncomeType) => void;
    close: () => void;
    incomeTypes: Array<IncomeType>
}

type IncomeTypeForForm = {
    id: string
    label: string
    rateLabel: string
    taxTreatmentCode: string;
    unitsLabel: string
}

function getTaxTreatmentMap(incomeTypes: Array<IncomeType>) {
    return new Map(
        incomeTypes
            .map(incomeType => [incomeType.taxTreatment.code, incomeType.taxTreatment])
    );
}


function getIncomeTypeForForm(incomeType: IncomeType): IncomeTypeForForm {
    return {
        id: incomeType.id,
        label: incomeType.label,
        rateLabel: incomeType.rateLabel,
        taxTreatmentCode: incomeType.taxTreatment.code,
        unitsLabel: incomeType.unitsLabel
    }
}

const IncomeTypeEdit = (props: PropsType) => {
    const {
        incomeType,
        setIncomeType,
        currentPayStubId,
        close,
        incomeTypes
    } = props;

    const [error, setError] = useState<string>();
    const {getAnonUserId} = useAnonCookie();
    const {sendNotice} = useSystemNotices();

    const [
        saveIncomeType,
        {
            loading: saveIncomeTypeLoading,
            error: saveIncomeTypeError
        }] = useSaveIncomeTypeMutation();

    const {
        register,
        control,
        formState: {errors},
        handleSubmit,
        watch
    } = useForm<IncomeTypeForForm>({
        defaultValues: getIncomeTypeForForm(incomeType)
    });

    const saveNewIncomeType = (data: IncomeTypeForForm) => {
        // setIncomeType(undefined);
        saveIncomeType({
            variables: {
                input: {
                    id: data.id,
                    label: data.label,
                    rateLabel: data.rateLabel,
                    unitsLabel: data.unitsLabel,
                    taxTreatmentCode: data.taxTreatmentCode
                },
                anonUserId: getAnonUserId(),
                payStubId: currentPayStubId
            },
            refetchQueries: [
                {
                    query: GET_INCOME_TYPES,
                    variables: {
                        anonUserId: getAnonUserId(),
                    },
                }
            ]
        })
            .then(savedIncomeType => {
                let responseData = savedIncomeType.data;
                if (!responseData) {
                    sendNotice(`Error saving income type on payStubId: ${currentPayStubId}`);
                    setError("Error saving income type. Please try again or contact support.")
                } else {
                    setIncomeType(responseData.saveIncomeType);
                    close();
                }
            });
    }

    const taxTreatmentMap = getTaxTreatmentMap(incomeTypes);
    const distinctTaxTreatments = Array.from(taxTreatmentMap.values());

    const taxTreatmentCode = watch('taxTreatmentCode');
    const selectedTaxTreatment = taxTreatmentMap.get(taxTreatmentCode);

    return <form noValidate onSubmit={handleSubmit(saveNewIncomeType)}>
        <Grid container spacing={2} sx={{p: 2}}>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={"Label"}
                    {...register("label", {required: "Label is required"})}
                    error={!!errors.label}
                    helperText={errors.label?.message}
                />
            </Grid>
            <Grid item xs={12}>
                {!!distinctTaxTreatments &&
                    <ReactHookFormSelect
                        control={control}
                        label={"Tax Treatment"}
                        nameOfFormField={`taxTreatmentCode`}
                        requiredMessage={"Tax treatment is required"}
                    >
                        {distinctTaxTreatments.map(taxTreatment => (
                            <MenuItem key={taxTreatment.code} value={taxTreatment.code}>
                                {taxTreatment.label}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>
                }
            </Grid>
            <Grid item xs={12} sx={{ml: 2, mb: 2}}>
                <Typography
                    variant="body2"
                >
                    {selectedTaxTreatment?.description}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label={"Rate Label"}
                    {...register("rateLabel", {required: "Rate label is required"})}
                    error={!!errors.rateLabel}
                    helperText={errors.rateLabel?.message}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label={"Units Label"}
                    {...register("unitsLabel", {required: "Units label is required"})}
                    error={!!errors.unitsLabel}
                    helperText={errors.unitsLabel?.message}
                />
            </Grid>
            <Grid item xs={6}>
                <Button
                    variant={"contained"}
                    fullWidth
                    color={"error"}
                    onClick={close}
                >
                    {"Cancel"}
                </Button>
            </Grid>
            <Grid item xs={6}>
                <Button
                    variant={"contained"}
                    fullWidth
                    type={"submit"}
                >
                    Save Income Type
                </Button>
            </Grid>
            <Grid item xs={12}>
                <LoadingErrorDisplay
                    loading={saveIncomeTypeLoading}
                    apolloError={saveIncomeTypeError}
                    stringError={error}
                />
            </Grid>
        </Grid>
    </form>

}
export default IncomeTypeEdit;