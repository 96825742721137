import * as React from 'react';
import {ReactNode, useEffect, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import LoadingErrorDisplay from "../common/LoadingErrorDisplay";
import {useGetSignedInUserLazyQuery} from "../graphql/generated/graphql";
import UserProfileDialog from "../common/UserProfileDialog";
import useSystemNotices from "../Utils/useSystemNotices";
import {REACT_CLIENT_APP_PREFERENCE, useSaveAppPreference} from "../common/useSaveAppPreference";

type PropsType = {
    children: ReactNode;
}

const UserProfile = (props: PropsType) => {

    const {
        isAuthenticated,
        isLoading: authLoading
    } = useAuth0();
    const [profileCreateRequired, setProfileCreateRequired] = useState(false);
    const [signedInCheckDone, setSignedInCheckDone] = useState(false);

    const {sendNotice} = useSystemNotices();
    const [
        getSignedInUser,
        {
            data: signedInUserData,
            loading: signedInUserLoading,
            error: signedInUserError
        }] = useGetSignedInUserLazyQuery();

    const {saveAppPreference} = useSaveAppPreference();


    useEffect(() => {
        if (authLoading) {
            return;
        }
        if (!isAuthenticated) {
            setSignedInCheckDone(true);
            return;
        }
        if (signedInCheckDone) {
            return;
        }
        if (signedInUserLoading) {
            return;
        }
        if (!!signedInUserError) {
            sendNotice(`Error in UserProfile. Error returned from getSignedInUser: ${signedInUserError.message}`);
            setSignedInCheckDone(true);
            return;
        }

        getSignedInUser()
            .then(result => {
                if (!result.data || !result.data.getSignedInUser.id) {
                    setProfileCreateRequired(true);
                }
                setSignedInCheckDone(true);

            })
            .catch(error => {
                setSignedInCheckDone(true);
                sendNotice(`Error in UserProfile. Error returned from getSignedInUser: ${error.message}`);
            });

    }, [getSignedInUser, setProfileCreateRequired, signedInCheckDone, sendNotice, signedInUserError, signedInUserLoading, authLoading, isAuthenticated]);


    if (!signedInCheckDone) {
        return <LoadingErrorDisplay
            loading={!signedInCheckDone}
            loadingMessage={"Loading user profile"}
        />
    }

    if (profileCreateRequired) {
        return <UserProfileDialog
            userProfile={signedInUserData?.getSignedInUser}
            profileCreated={userProfile => {
                setProfileCreateRequired(false);
                saveAppPreference(REACT_CLIENT_APP_PREFERENCE, userProfile.email);
            }}
        />
    }

    return <>
        {props.children}
    </>
}
export default UserProfile;