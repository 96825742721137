import {useCookies} from "react-cookie";
import {useCallback} from "react";
import {ANON_USER_COOKIE_KEY} from "../graphql/AnonUserProvider";
import {useLocation} from "react-router-dom";
import {useSendSystemNoticeMutation} from "../graphql/generated/graphql";

type ReturnType = {
    getAnonUserId: () => string;
}
let noticeSent = false;
const useAnonCookie = (): ReturnType => {

    const [
        cookies
    ] = useCookies([ANON_USER_COOKIE_KEY]);

    // note, we can't use the useSystemNotices hook as that one uses this hook.
    const [
        sendNotice,
    ] = useSendSystemNoticeMutation();

    const location = useLocation();

    const getAnonUserId = useCallback(() => {
        const anonUserId = cookies.anon_user_id;
        if (!anonUserId && !noticeSent) {
            console.error("anonUserId not set");
            sendNotice({
                variables: {
                    message: `anonUserId is empty in useAnonCookie! url: ${location.pathname}`,
                    anonUserId: ""
                }
            })
                .then(() => {/*do nothing.*/
                })
            noticeSent = true;
        }
        return anonUserId || "";
    }, [cookies.anon_user_id, sendNotice, location]);

    return {
        getAnonUserId
    }
}

export default useAnonCookie;