import React, {useState} from "react";
import {Card, Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {EmployeeWithStatus} from "../../graphql/generated/graphql";
import EmployeesSelect from "./EmployeesSelect";

type PropsType = {
    openDialog: boolean;
    close: () => void;
    reportName: string;
    getReportParamsForm: (employeesSelected: Array<EmployeeWithStatus>) => JSX.Element;
}


const ReportGeneratorModal = (props: PropsType) => {
    const {openDialog, reportName, getReportParamsForm, close} = props;
    const [selectedEmployees, setSelectedEmployees] = useState<Array<EmployeeWithStatus>>([]);
    const [showParamsForm, setShowParamsForm] = useState(true);

    return (
        <Dialog
            open={openDialog}
            onClose={close}
            maxWidth="lg"
            fullWidth={true}
            sx={{'& .MuiDialog-paper': {minWidth: 'lg'}}}
        >
            <DialogTitle sx={{m: 0, p: 2, fontSize: '1.5rem'}}>
                {reportName}
                <IconButton
                    aria-label="close"
                    onClick={close}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Card sx={{p: 2, boxShadow: 4}}>
                    <EmployeesSelect
                        employeesSelectedUpdated={setSelectedEmployees}
                        selectedEmployees={selectedEmployees}
                        multipleSelectionsAllowed={true}
                        showNeighbours={showEm => setShowParamsForm(showEm)}
                    />
                    {showParamsForm && getReportParamsForm(selectedEmployees)}
                </Card>
            </DialogContent>
        </Dialog>
    );
}

export default ReportGeneratorModal;