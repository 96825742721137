import * as React from 'react';
import {ReactNode, useCallback, useEffect, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {ApolloClient, ApolloProvider, NormalizedCacheObject} from "@apollo/client";
import {getOneTwoPayApolloClient} from "./ApolloClient";
import {jwtDecode} from 'jwt-decode';
import LoadingErrorDisplay from "../common/LoadingErrorDisplay";
import {useNavigate} from "react-router-dom";
import {Button, Dialog, DialogActions, DialogContent, Typography} from "@mui/material";
import {MARKETING_LINK} from "../common/AppLinks";


interface DecodedToken {
    exp: number;

    [key: string]: any;
}

type PropsType = {
    children: ReactNode
}

export let APOLLO_CLIENT: ApolloClient<any> | undefined = undefined;

export function tokenExpired(token: string): boolean {
    const decodedToken: DecodedToken = jwtDecode(token);
    if (!decodedToken) {
        console.error("could not decode token");
        return true;
    }
    const expirationDate: Date = new Date(decodedToken.exp * 1000); // since it's a unix timestamp
    const currentDate: Date = new Date();
    return expirationDate < currentDate;
}

const OneTwoPayApolloProvider = (props: PropsType) => {
    const [accessToken, setAccessToken] = useState<string>();
    const [authError, setAuthError] = useState<string>();
    const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
    const [openMissingAnonUserDialog, setOpenMissingAnonUserDialog] = useState(false);

    const navigate = useNavigate();
    const {
        isLoading: authLoading,
        isAuthenticated,
        getAccessTokenSilently
    } = useAuth0();

    const handleAnonUserMissing = useCallback(() => {
        setOpenMissingAnonUserDialog(true);
    }, []);

    const userNotFoundAction = useCallback(() => {
        navigate('/employees');
    }, [navigate]);

    const getNewAccessToken = useCallback(async () => {
        if (isAuthenticated) {
            try {
                return await getAccessTokenSilently();
            } catch (error: any) {
                setAuthError("Error getting access token. Please try again or contact OneTwoPay support.");
            }
        }
    }, [getAccessTokenSilently, setAuthError, isAuthenticated]);


    const getAndSetNewAccessToken = useCallback(async () => {
        const token = await getNewAccessToken();
        setAccessToken(token);
        return Promise.resolve(token);
    }, [getNewAccessToken, setAccessToken]);

    const tokenExistsAndIsExpired = !!accessToken && tokenExpired(accessToken);

    useEffect(() => {
        if (authLoading) {
            // console.log("auth is loading");
            return;
        }
        if (!isAuthenticated) {
            // console.log("not authenticated. getting apollo client without token.");
            setClient(getOneTwoPayApolloClient(undefined, getAndSetNewAccessToken, userNotFoundAction, handleAnonUserMissing).client);
            return;
        }
        if (!accessToken || tokenExistsAndIsExpired) {
            // console.log("getting new token");
            getAndSetNewAccessToken().then((token) => {
                // console.log("getting apollo client with new token");
                setClient(getOneTwoPayApolloClient(token, getAndSetNewAccessToken, userNotFoundAction, handleAnonUserMissing).client);
            });
            return;
        }
        // console.log("hit end of useEffect without doing anything.");
    }, [accessToken, getAndSetNewAccessToken, isAuthenticated, authLoading, tokenExistsAndIsExpired, userNotFoundAction, handleAnonUserMissing]);

    if (openMissingAnonUserDialog) {
        return <Dialog
            open={openMissingAnonUserDialog}
        >
            <DialogContent>
                <Typography
                    sx={{fontSize: "1.5rem"}}
                >
                    Could not find user session. Please sign in or sign up.
                    Alternatively, OneTwoPay does allow creating pay stubs without being signed in but we use cookies to
                    keep your data secure.
                    Please enable cookies in your browser settings and try again.
                    Please feel free to contact OneTwoPay support for assistance if needed.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant={"contained"}
                    size={'large'}
                    onClick={() => window.location.href = `${MARKETING_LINK}`}
                >
                    Home
                </Button>
                <Button
                    variant={"contained"}
                    size={'large'}
                    onClick={() => setOpenMissingAnonUserDialog(false)}
                >
                    Try again

                </Button>
            </DialogActions>
        </Dialog>

    }

    // This happens when a user initially signs in. Need time to update the client (to one with a token) before any graphql requests are sent.
    const authenticatedWithoutToken = isAuthenticated && !accessToken;

    if (authLoading || authError || !client || authenticatedWithoutToken) {
        // console.log("END OF RENDER APOLLO PROVIDER: loading-error display");
        return <LoadingErrorDisplay
            loading={authLoading || !client}
            stringError={authError}
        />
    }
    // console.log("END OF RENDER APOLLO PROVIDER: client populated");

    APOLLO_CLIENT = client;

    return <>
        <ApolloProvider
            client={client}>
            {props.children}
        </ApolloProvider>
    </>


}

export default OneTwoPayApolloProvider;