import React from 'react';
import {Grid} from "@mui/material";
import {Link} from "react-router-dom";
import styles from './Footer.module.scss';

import logo from '../../images/logoDark.svg'
import { MARKETING_LINK } from '../../common/AppLinks';





const Footer = () => {
    return (
        <Grid>
            <Grid className={styles.footerWrapper}>
                <Grid container className="container" spacing={4} justifyContent="space-between">
                    <Grid item xs={12} md={4}>
                        <Grid className={styles.footerContact}>
                            <Link to="/">
                                <>
                                    <img src={logo} alt={"OneTwoPay Logo"}
                                         width={125}
                                         height={125}>
                                    </img>
                                </>
                            </Link>
                            <ul>
                                <li>1 (800) 837-8793</li>
                                <li>info@onetwopay.ca</li>
                            </ul>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} sm={6}>
                        {/*<Grid className={`${styles.widget} ${styles.&haslpadding}`}>*/}
                        <Grid className={`${styles.widget}`}>
                            <h4 className={styles.widgetTitle}>Quick Link</h4>
                            <ul className={styles.quickLinks}>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to={MARKETING_LINK + "/our-pay-stubs"} target="_blank">Our Pay Stubs</Link></li>
                                <li><Link to= {MARKETING_LINK + "/contact-us"} target="_blank">Contact</Link></li>
                                <li><Link to={MARKETING_LINK + "/pricing"} target="_blank">Pricing</Link></li>
                            </ul>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} sm={6}>
                        <h4 className={styles.widgetTitle}>Services</h4>
                        <ul className={styles.quickLinks}>
                            <li><Link to={MARKETING_LINK + "/faq"} target="_blank">FAQ</Link></li>
                            <li><Link to={MARKETING_LINK + "/about-us"} target="_blank">About Us</Link></li>
                            <li><Link to={MARKETING_LINK + "/terms-of-service"} target="_blank">Terms of Service</Link></li>
                            <li><Link to={MARKETING_LINK + "/privacy-policy"} target="_blank">Privacy Policy</Link></li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid className="container">
                    <Grid className={styles.footerBottom}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <p>Copyright 2023 OneTwoPay</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    )
};

export default Footer;