import * as React from 'react';
import {ReactNode, useCallback, useEffect, useState} from 'react';
import LoadingErrorDisplay from "../common/LoadingErrorDisplay";
import {generateRandomString} from "../Utils/randomString";
import {useAuth0} from "@auth0/auth0-react";
import {Button, Grid} from "@mui/material";
import {APP_LINK_LOG_IN} from "../common/AppLinks";
import useCookieManager from "../Utils/useCookieManager";


type PropsType = {
    children: ReactNode
}

export const ANON_USER_COOKIE_KEY = 'anon_user_id';

const AnonUserProvider = (props: PropsType) => {

    const {
        getDomainCookie: getAnonUserId,
        setDomainCookie: setAnonUserId
    } = useCookieManager(ANON_USER_COOKIE_KEY);

    const {
        isAuthenticated
    } = useAuth0();

    const [triedOnce, setTriedOnce] = useState(false);

    const setAnonUserCookie = useCallback(() => {
        // console.log("creating / setting anon user id cookie in AnonUserProvider");
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 5);
        setAnonUserId(generateRandomString(10), expiryDate);
        // console.log("anon user id cookie should be set now");
        setTimeout(() => setTriedOnce(true), 500);
    }, [setAnonUserId]);

    // console.log("getting anon user id using getDomainCookie");
    const anonUserId = getAnonUserId();

    useEffect(() => {
        if (!anonUserId) {
            // console.log("no anon user id cookie found in AnonUserProvider");
            // Comment out the following line for testing as described below.
            setAnonUserCookie();

            // Uncomment these two lines to simulate a scenario where the cookie cannot be set because the browser does not allow it or any other reason.
            // This simulates the first attempt happening in 2 seconds and failing. Then in 48 seconds, the attempt is successful.
            // setTimeout(setAnonUserCookie, 50000); // for testing
            // setTimeout(() => setTriedOnce(true), 2000); // for testing
        }
    }, [anonUserId, setAnonUserCookie]);


    if (!!anonUserId || isAuthenticated) {
        // console.log("rendering children of the AnonUserProvider");
        return <>
            {props.children}
        </>
    }

    // console.log("showing user the anonUserId requirement message.");
    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={8} lg={4} sx={{mt: 10}}>
                <LoadingErrorDisplay
                    loading={!triedOnce}
                    stringError={"To keep your data secure, this service requires users to be signed in or have cookies enabled. To proceed, please sign in or ensure cookies are enabled and then refresh this page."}
                />
                <Grid container justifyContent="center">
                    {
                        triedOnce &&
                        <Button
                            variant='contained'
                            href={APP_LINK_LOG_IN}
                            style={{marginTop: '25px', fontSize: '15px', padding: '10px 20px'}}
                        >
                            Sign in
                        </Button>
                    }
                </Grid>
            </Grid>
        </Grid>
    );


}

export default AnonUserProvider;