import {Province, useGetProvincesQuery} from "../graphql/generated/graphql";
import {ApolloError} from "@apollo/client";

type ReturnType = {
    provinces: Array<Province> | undefined;
    loading: boolean;
    error: ApolloError | undefined;
}

function sortProvinces(provinces: Array<Province> | undefined) {
    if(!provinces){
        return undefined;
    }
    const provincesCopy = [...provinces];
    provincesCopy.sort((option1, option2) => {
        let text2Upper = option2.key.toUpperCase();
        let text1Upper = option1.key.toUpperCase();
        return text1Upper < text2Upper ? -1 : text1Upper > text2Upper ? 1 : 0;
    });
    return provincesCopy;
}
const useGetProvinces = (): ReturnType => {

    const {
        data,
        loading,
        error
    } = useGetProvincesQuery();

    return {
        provinces: sortProvinces(data?.getProvinces),
        loading,
        error
    }
}

export default useGetProvinces;