import {useFieldArray, useForm} from "react-hook-form";
import {SpecialPaymentsCode, SpecialPaymentsInput, SpecialPaymentsPeriod} from "../../../graphql/generated/graphql";
import {Button, Dialog, DialogContent, Divider, Grid, MenuItem, Typography} from "@mui/material";
import React from "react";
import ReactHookFormDatePicker from "../../../common/ReactHookFormDatePicker";
import dayjs from "dayjs";
import {DATE_FORMAT, getStringFromDate} from "../../../Utils/dateFormatter";
import ReactHookFormSelect from "../../../common/ReactHookFormSelect";
import OneTwoPayNumericTextField from "../../../common/OneTwoPayNumericTextField";

type PropsType = {
    specialPayments: Array<SpecialPaymentsInput> | undefined | null
    updateSpecialPayments: (specialPayments: Array<SpecialPaymentsInput>) => void;
    close: () => void;
}

type SpecialPaymentsArray = {
    specialPayments: Array<SpecialPayments>
}
type SpecialPayments = {
    amount?: string | undefined | null;
    startDate?: string | undefined | null;
    endDate?: string | undefined | null;
    specialPaymentsPeriod?: SpecialPaymentsPeriod | null | undefined;
    specialPaymentsCode: SpecialPaymentsCode;
}

function getDefaultSpecialPayment() {
    return {
        amount: "",
        startDate: getStringFromDate(dayjs(), DATE_FORMAT),
        endDate: getStringFromDate(dayjs(), DATE_FORMAT),
        specialPaymentsPeriod: SpecialPaymentsPeriod.D,
        specialPaymentsCode: SpecialPaymentsCode.Psl01PaidSickLeaveEiInsurable
    };
}

function getAtLeastOneSpecialPayment(specialPayments: Array<SpecialPaymentsInput> | undefined | null): Array<SpecialPayments> {
    if (!specialPayments || specialPayments.length < 1) {
        return [getDefaultSpecialPayment()];
    }
    return specialPayments;
}

const RoeSpecialPayments = (props: PropsType) => {
    const {specialPayments, updateSpecialPayments, close} = props;
    const {
        control,
        register,
        getValues,
        formState: {errors},
    } = useForm<SpecialPaymentsArray>({
        defaultValues: {
            specialPayments: getAtLeastOneSpecialPayment(specialPayments)
        }
    });

    const {fields, append} = useFieldArray({
        control,
        name: "specialPayments",
    });

    function saveSpecialPayments() {
        const data = getValues();
        updateSpecialPayments(data.specialPayments);
        close();
    }

    function addAnotherSpecialPayments() {
        append(getDefaultSpecialPayment());
    }

    const codes = Object.values(SpecialPaymentsCode);
    const periods = Object.values(SpecialPaymentsPeriod);
    return <Dialog
        open={true}
        fullWidth={true}
    >
        <DialogContent>
            <Typography variant={"h6"}>Other monies information (optional)</Typography>
            <form noValidate onSubmit={e => {
                saveSpecialPayments();
                e.preventDefault();
                e.stopPropagation();
            }}>
                <Grid container spacing={2}>
                    {fields.map((field, index) => (
                        <Grid item xs={12} key={field.id}>
                            <Grid container spacing={2} sx={{mt: 1}}>
                                <Grid item xs={8}>
                                    <OneTwoPayNumericTextField
                                        label={"Amount"}
                                        amount={getValues(`specialPayments.${index}.amount`) || ""}
                                        {...register(`specialPayments.${index}.amount`)}
                                        error={!!errors.specialPayments && !!errors.specialPayments[index]?.amount}
                                        errorText={errors.specialPayments && errors.specialPayments[index]?.amount?.message}
                                        variant={"outlined"}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <ReactHookFormDatePicker
                                        fieldName={`specialPayments.${index}.startDate`}
                                        label={"Start date"}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <ReactHookFormDatePicker
                                        fieldName={`specialPayments.${index}.endDate`}
                                        label={"End date"}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <ReactHookFormSelect
                                        control={control}
                                        label={"Special payments code"}
                                        nameOfFormField={`specialPayments.${index}.specialPaymentsCode`}
                                        requiredMessage={"Special payments code is required"}
                                    >
                                        {codes.map(code => (
                                            <MenuItem key={code} value={code}>
                                                {code}
                                            </MenuItem>
                                        ))}
                                    </ReactHookFormSelect>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <ReactHookFormSelect
                                        control={control}
                                        label={"Special payments period (Daily or Weekly)"}
                                        nameOfFormField={`specialPayments.${index}.specialPaymentsPeriod`}
                                    >
                                        {periods.map(period => (
                                            <MenuItem key={period} value={period}>
                                                {period}
                                            </MenuItem>
                                        ))}
                                    </ReactHookFormSelect>
                                </Grid>
                            </Grid>
                            <Divider sx={{borderColor: 'grey', borderWidth: '.5px', width: '100%', mt: 2}}/>
                        </Grid>

                    ))}
                    <Grid item xs={12}>
                        <Button onClick={() => addAnotherSpecialPayments()}>
                            Add another special payment
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant={"contained"}
                            style={{fontSize: '1.2rem'}}
                            color={"error"}
                            onClick={() => close()}
                            size={"large"}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant={"contained"}
                            type={"submit"}
                            style={{fontSize: '1.2rem'}}
                            size={"large"}
                        >
                            Save special payments
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </DialogContent>
    </Dialog>
}
export default RoeSpecialPayments;