import {gql} from "@apollo/client";

const GET_INCOME_TYPES = gql`

    query getIncomeTypes($anonUserId: String!) {
        getIncomeTypes(anonUserId: $anonUserId) {
            id
            code
            label
            rateLabel
            unitsLabel
            taxTreatment {
                code
                label
                description
            }
            displayOrder
            isBenefit
        }
    }`;

export default GET_INCOME_TYPES;