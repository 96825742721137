import {gql} from "@apollo/client";
import {EMPLOYEE_FRAGMENT} from "./getEmployee";

const GET_EMPLOYEES_WITH_STATUS = gql`
    query getEmployeesWithStatus {
        getEmployeesWithStatus {
            id
            employee {
                ...employeeFields
            }
            recommendedActions {
                description
                employeeId
            }
        }
    }
    ${EMPLOYEE_FRAGMENT}
`;

export default GET_EMPLOYEES_WITH_STATUS;