import React, {useState} from "react";
import EmployeesSelect from "../components/Report/EmployeesSelect";
import {EmployeeWithStatus} from "../graphql/generated/graphql";
import {Box, Container, Dialog, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RoeInput from "../components/Report/Roe/RoeInput";

type PropsType = {
    closeReport: () => void;
}

const RoeReport = (props: PropsType) => {
    const [employeesSelected, setEmployeesSelected] = useState<Array<EmployeeWithStatus>>();
    const employee = !!employeesSelected && employeesSelected.length > 0 ? employeesSelected[0] : undefined;
    const [reloadEmployees, setReloadEmployees] = useState<boolean>(true);
    const [showRoeForm, setShowRoeForm] = useState(true);

    const {closeReport} = props;

    return <Dialog
        open={true}
        maxWidth="lg"
        fullWidth={true}
        sx={{'& .MuiDialog-paper': {minWidth: 'lg'}}}
    >
        <DialogTitle sx={{m: 0, p: 2, fontSize: '1.5rem'}}>
            {
                // todo: extract this close icon into a common component.
            }
            <IconButton
                aria-label="close"
                onClick={() => {
                    closeReport();
                }}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <Container maxWidth={"lg"}>
                <Box sx={{height: "auto", width: '100%', marginTop: "10px"}}>
                    <Typography variant={"h4"} sx={{pb: 1.5}}>
                        Please select an employee to continue.
                    </Typography>
                    <Typography>
                        Note that only employees with finalized pay stubs are shown here.
                    </Typography>

                    <EmployeesSelect
                        employeesSelectedUpdated={setEmployeesSelected}
                        selectedEmployees={employeesSelected}
                        multipleSelectionsAllowed={false}
                        reloadEmployees={reloadEmployees}
                        reloaded={() => setReloadEmployees(false)}
                        showNeighbours={showEm => setShowRoeForm(showEm)}
                    />

                    {!!employee && showRoeForm && (
                        <Dialog
                            open={!!employee}
                            fullWidth={true}
                            maxWidth="md"
                            sx={{
                                '& .MuiDialog-paper': {
                                    maxHeight: '90vh'
                                },
                            }}
                        >
                            <DialogTitle>
                                <IconButton
                                    aria-label="close"
                                    onClick={() => setEmployeesSelected(undefined)}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <CloseIcon sx={{fontSize: "24px"}}/>
                                </IconButton>
                            </DialogTitle>
                            <RoeInput
                                employeeWithStatus={employee}
                                close={() => setEmployeesSelected(undefined)}
                                reloadEmployee={() => setReloadEmployees(true)}
                            />
                        </Dialog>
                    )}
                </Box>
            </Container>
        </DialogContent>
    </Dialog>
}

export default RoeReport;