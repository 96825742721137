import React, {useCallback, useEffect, useState} from "react";
import PayStubDisplayEdit, {ParamsType} from "./PayStubDisplayEdit";
import {useLocation, useParams} from "react-router-dom";
import {AccessCheckResult, useCheckPayStubAccessLazyQuery} from "../graphql/generated/graphql";
import useAnonCookie from "../security/useAnonCookie";
import {getNumber} from "../Utils/stringMath";
import LoadingErrorDisplay from "../common/LoadingErrorDisplay";
import PayStubAccessDialog from "../components/PayStub/PayStubAccessDialog";
import useSystemNotices from "../Utils/useSystemNotices";
import {useAuth0} from "@auth0/auth0-react";

export function userSignedInWithoutAccessButSameAnonUser(payStubAccess: AccessCheckResult): boolean {
    return payStubAccess.userSignedIn && !payStubAccess.signedInAccess && !!payStubAccess.anonymousAccess;
}

export function userNotSignedInAndNoAccess(payStubAccess: AccessCheckResult): boolean {
    return !payStubAccess.userSignedIn && !payStubAccess.anonymousAccess;
}

export function userSignedInWithoutAccessAndDifferentAnonUser(payStubAccess: AccessCheckResult): boolean {
    return payStubAccess.userSignedIn && !payStubAccess.signedInAccess && !payStubAccess.anonymousAccess;
}


function shouldShowAccessDialog(payStubAccessData: AccessCheckResult) {
    return userSignedInWithoutAccessButSameAnonUser(payStubAccessData) ||
        userNotSignedInAndNoAccess(payStubAccessData) ||
        userSignedInWithoutAccessAndDifferentAnonUser(payStubAccessData) ||
        !payStubAccessData.payStubExists;
}

// note that this component is not meant to be a security gate. It is just meant to give the user a meaningful message and options when they don't have access to a pay stub.
// the pay stub endpoints on the server will enforce access appropriately if this component renders the pay stub when the user doesn't have access.
const PayStubWithAccessCheck = () => {

    const {paramsPayStubId} = useParams<ParamsType>();
    const {getAnonUserId} = useAnonCookie();
    const {sendNotice} = useSystemNotices();
    const {isAuthenticated} = useAuth0();
    const location = useLocation();
    const payStubId = getNumber(paramsPayStubId);


    const [accessData, setAccessData] = useState<AccessCheckResult>();
    const [accessCheckComplete, setAccessCheckComplete] = useState(false);
    const [accessCheckError, setAccessCheckError] = useState<string>();

    const [
        getPayStubAccessData,
        {
            data: payStubAccessData,
            loading: payStubAccessLoading,
            error: payStubAccessError
        }] = useCheckPayStubAccessLazyQuery({
        fetchPolicy: "no-cache"
    });

    const loadPayStubAccess = useCallback(() => {
        const anonUserId = getAnonUserId();
        if (!isAuthenticated && !anonUserId) {
            sendNotice(`User not authenticated and no anonUserId available. Url: ${location.pathname}`);
            setAccessCheckError("No session found. Please refresh your browser or sign in to access this pay stub.");
            return;
        }
        getPayStubAccessData({
            variables: {
                payStubId: payStubId,
                anonUserId: anonUserId
            }
        })
            .then(result => {
                setAccessData(result.data?.checkPayStubAccess);
                if (!result.data?.checkPayStubAccess) {
                    sendNotice(`Pay stub access request did not return anything for pay stub ID: ${payStubId}`);
                }
                setAccessCheckComplete(true);
            })
            .catch(error => {
                sendNotice(`Error retrieving pay stub access: ${error.message} for pay stub ID: ${payStubId}`);
                setAccessCheckComplete(true);
            });
    }, [payStubId, getPayStubAccessData, sendNotice, getAnonUserId, isAuthenticated, location.pathname]);

    useEffect(() => {
        if (!payStubAccessData && !payStubAccessLoading && !payStubAccessError) {
            loadPayStubAccess();
        }
    }, [loadPayStubAccess, payStubAccessData, payStubAccessLoading, payStubAccessError]);


    if (payStubAccessLoading || payStubAccessError || !accessCheckComplete || !!accessCheckError) {
        return <LoadingErrorDisplay
            loading={payStubAccessLoading}
            apolloError={payStubAccessError}
            stringError={accessCheckError}
        />
    }

    if (!!accessData && shouldShowAccessDialog(accessData)) {
        return <PayStubAccessDialog
            payStubAccessData={accessData}
            payStubId={payStubId}
            refreshAccessData={() => loadPayStubAccess()}
        />
    }

    return <PayStubDisplayEdit/>
}
export default PayStubWithAccessCheck;