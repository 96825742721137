import dayjs, {Dayjs} from "dayjs";
import {DateRange} from "@mui/x-date-pickers-pro";

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_WITH_MONTH = 'MMM DD, YYYY';
const INVALID_DATE_STRING_RESULT = "Invalid Date";
export function getFormattedDateStringFromNullableDateOrString(date: dayjs.Dayjs | string | null | undefined) {
    return getStringFromNullableDate(getDateFromNullableStringOrDate(date), DATE_FORMAT);
}

export function getFormattedDateStringWithMonthFromNullableDateOrString(date: dayjs.Dayjs | string | null | undefined) {
    return getStringFromNullableDate(getDateFromNullableStringOrDate(date), DATE_FORMAT_WITH_MONTH);
}

export function isDateToStringResultInvalid(dateToStringResult: string): boolean {
    return dateToStringResult === INVALID_DATE_STRING_RESULT;
}

export function getDateFromNullableStringOrDate(date: dayjs.Dayjs | string | null | undefined) {
    let dateValue: dayjs.Dayjs | null | undefined = null;
    if (typeof date === 'string' && !!date) {
        dateValue = getDateFromString(date);
    } else if (dayjs.isDayjs(date)) {
        dateValue = date;
    }
    return dateValue;
}

export function getStringFromNullableDate(date: dayjs.Dayjs | null | undefined, dateFormat: string) {
    if (!date) {
        return undefined;
    }
    return getStringFromDate(date, dateFormat);
}

export function getStringFromDate(date: dayjs.Dayjs, dateFormat: string) {
    return date.format(dateFormat);
}

export function getTodayAsString() {
    return getStringFromDate(dayjs(), DATE_FORMAT);
}

export function getDateFromString(date: string) {
    return dayjs(date);
}

export function getDateRangeFromStrings(start: string, end: string): DateRange<Dayjs> {
    return [
        dayjs(start),
        dayjs(end),
    ];
}

export function getDateFromNullableString(date: string | undefined | null) {
    if (!date) {
        return undefined
    }
    return getDateFromString(date);
}

export function getYearFromNullableDateOrString(dateAsStringOrDate: string | undefined | null) {
    const date = getDateFromNullableStringOrDate(dateAsStringOrDate);
    if (!date) {
        return undefined;
    }
    return date.year();
}