import {Controller, FieldPath, FieldValues, Path} from "react-hook-form";
import React from "react";
import {Control} from "react-hook-form/dist/types";
import {DATE_FORMAT, getDateFromString, getFormattedDateStringFromNullableDateOrString} from "../Utils/dateFormatter";
import dayjs, {Dayjs} from "dayjs";
import {DateRangePicker} from '@mui/x-date-pickers-pro/DateRangePicker';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {
    DateRange,
    PickersShortcutsItem,
    PickersShortcutsProps,
    SingleInputDateRangeField
} from "@mui/x-date-pickers-pro";
import {Box, Chip, Divider, Grid, List, ListItem} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
    datePickerContainer: {
        width: '100%', // Adjust the width as needed
    },
}));

type PropsType<TFormValues extends FieldValues> = {
    fieldName: FieldPath<TFormValues>;
    label: string;
    control: Control<TFormValues>;
    required?: boolean
    getFullFieldDataObject?: (dates: Array<string | undefined>) => any;
}
const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
        label: 'Current Month',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('month'), today.endOf('month')];
        },
    },
    {
        label: 'Last Month',
        getValue: () => {
            const today = dayjs();
            const endOfLastMonth = today.startOf('month').subtract(1, 'day');
            return [endOfLastMonth.startOf('month'), endOfLastMonth];
        },
    },
    {label: 'Reset', getValue: () => [null, null]},
];

function CustomRangeShortcuts(props: PickersShortcutsProps<DateRange<Dayjs>>) {
    const {items, onChange, isValid, changeImportance = 'accept'} = props;

    if (items == null || items.length === 0) {
        return null;
    }

    const resolvedItems = items.map((item) => {
        const newValue = item.getValue({isValid});

        return {
            label: item.label,
            onClick: () => {
                onChange(newValue, changeImportance, item);
            },
            disabled: !isValid(newValue),
        };
    });

    return (
        <Box
            sx={{
                gridRow: 1,
                gridColumn: 2,
            }}
        >
            <List
                dense
                sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    px: theme.spacing(10),
                    width: '100%',
                    overflow: 'hidden',
                    '& .MuiListItem-root': {
                        pt: 0,
                        pb: 0,
                        pl: 0,
                        pr: theme.spacing(1),
                        minWidth: 0,
                        flexGrow: 1,
                        justifyContent: 'center',
                        display: 'flex'
                    },
                })}
            >
                {resolvedItems.map((item) => (
                    <ListItem key={item.label} disableGutters>
                        <Chip
                            label={item.label}
                            onClick={item.onClick}
                            disabled={item.disabled}
                            sx={{
                                backgroundColor: '#1976d2',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: '#1565c0'
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: '#bbdefb',
                                    color: '#fff'
                                }
                            }}
                        />
                    </ListItem>
                ))}
            </List>
            <Divider/>
        </Box>
    );
}

const ReactHookFormDateRangePicker = <TFormValues extends FieldValues>(props: PropsType<TFormValues>) => {

    // todo: Kenny. Need to get the full range selected instead of just the two dates.
    // todo: Hawk. This is the default. I think its because of our weird change but couldnt get it to work.
    const classes = useStyles();
    const {fieldName, control, label, required, getFullFieldDataObject} = props;
    const name = fieldName as Path<TFormValues>;
    return <Controller
        name={name}
        control={control}
        rules={{required: required ? `${name} is required` : false}}
        render={({field}) => {
            let value: string | dayjs.Dayjs = field.value;
            if (typeof value === 'string' && !!value) {
                value = getDateFromString(value);
            }
            return (
                <Grid container direction="row" alignItems="center">
                    <Grid className={classes.datePickerContainer}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateRangePicker
                                slots={{
                                    shortcuts: CustomRangeShortcuts,
                                    field: SingleInputDateRangeField
                                }}
                                slotProps={{
                                    shortcuts: {
                                        items: shortcutsItems,
                                    },
                                    actionBar: {actions: []},
                                }}
                                label={label}
                                // value={value} // todo: convert DateRange object in form to dayjs array
                                format={DATE_FORMAT}
                                onChange={(newValue) => {
                                    // todo: make both start and end dates required somehow.
                                    // console.log("newValue: ", JSON.stringify(newValue, null, 2));
                                    const startDate = getFormattedDateStringFromNullableDateOrString(newValue[0]);
                                    const endDate = getFormattedDateStringFromNullableDateOrString(newValue[1]);
                                    let value = [startDate, endDate];
                                    if (!!getFullFieldDataObject) {
                                        value = getFullFieldDataObject(value);
                                    }
                                    field.onChange(value);
                                }}
                                sx={{maxWidth: '100%', width: '300px',}}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            );
        }}
    />
}
export default ReactHookFormDateRangePicker;