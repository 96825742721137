import React from "react";
import {Typography} from "@mui/material";

type PropsType = {
    messages: string | Array<string>;
}

const SuccessMessageDisplay = (props: PropsType) => {
    const {messages} = props;
    let allMessages: Array<string> = [];
    if (!!messages && typeof messages === "string") {
        allMessages.push(messages);
    } else if (Array.isArray(messages)) {
        allMessages = messages;
    }

    return <Typography
        component={'span'}
        variant="body2"
        fontSize={"1.5rem"}
        color={"green"}
    >
        {allMessages.map((item, index) => (
            <p key={index}>{`• ${item}`}</p>
        ))}
    </Typography>
}
export default SuccessMessageDisplay;