import React, {useEffect, useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import {useGeneratePayStubLazyQuery} from '../../graphql/generated/graphql'
import LoadingErrorDisplay from '../../common/LoadingErrorDisplay'
import useAnonCookie from '../../security/useAnonCookie'
import {Document, Page} from "react-pdf";
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import useViewerStyle from "./useViewerStyle";
import CloseDialogButton from "../../common/CloseDialogButton";
import {useAuth0} from "@auth0/auth0-react";
import useSystemNotices from "../../Utils/useSystemNotices";


type PropsType = {
    showPreview: boolean;
    setShowPreview: (show: boolean) => void;
    payStubId: number;
}

const PayStubPreview = (props: PropsType) => {
    const {showPreview, setShowPreview, payStubId} = props
    const {getAnonUserId} = useAnonCookie()
    const [error, setError] = useState<string | undefined | null>();
    const {isAuthenticated} = useAuth0();
    const {sendNotice} = useSystemNotices();
    let anonUserId = getAnonUserId();


    const [
        generatePayStub, {
            data: generatePayStubData,
            loading: generatePayStubLoading,
        }] = useGeneratePayStubLazyQuery();


    useEffect(() => {
        if (!anonUserId && !isAuthenticated) {
            sendNotice(`Cannot preview pay stub id: ${payStubId} because the user is not authenticated and there is no anonUserId`);
            return;
        }
        generatePayStub({
            variables: {
                payStubId: payStubId,
                anonUserId: anonUserId
            },
            fetchPolicy: "no-cache"
        })
            .then(result => {
                if (!result.data?.generatePayStub.generated) {
                    sendNotice(`Error generating preview for pay stub id: ${payStubId}. Error message: ${result.data?.generatePayStub.message}`);
                    setError(result.data?.generatePayStub.message);
                    return;
                }

            })
            .catch(error => {
                sendNotice(`Error generating preview for pay stub id: ${payStubId}. Error message: ${error.message}`);
                setError(error.message);
            });

    }, [isAuthenticated, anonUserId, generatePayStub, sendNotice, payStubId]);

    const {getPdfViewStyle} = useViewerStyle();

    const payStubUrl = generatePayStubData?.generatePayStub?.link;
    return (
        <Dialog
            open={showPreview}
            fullWidth
            sx={{"& .MuiPaper-root": {maxHeight: 700}}}
        >
            <DialogTitle>

                Pay Stub Preview
                <CloseDialogButton
                    close={() => setShowPreview(false)}
                />
            </DialogTitle>
            <DialogContent style={{maxHeight: "calc(100vh - 200px)", overflow: 'auto'}}>
                {
                    (!payStubUrl || !!error) &&
                    (
                        <LoadingErrorDisplay
                            loading={generatePayStubLoading}
                            stringError={error}
                        />
                    )
                }
                {payStubUrl && (
                    <div style={getPdfViewStyle()}>
                        <Document
                            file={payStubUrl}
                        >
                            <Page pageNumber={1}/>
                        </Document>
                    </div>
                )}

            </DialogContent>
            <DialogActions
                sx={{justifyContent: 'flex-start'}}
            >
                <Button
                    sx={{mt: 1, fontSize: '1.5rem'}}
                    variant={'contained'}
                    size={'large'}
                    onClick={() => setShowPreview(false)}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default PayStubPreview