import {IconButton} from "@mui/material";
import OneTwoPayTooltip from "./OneTwoPayTooltip";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";


type PropsType = {
    toolTip: string
    deleteAction: () => void;
}

const OneTwoPayDeleteIcon = (props: PropsType) => {
    const {toolTip, deleteAction} = props;
    return <OneTwoPayTooltip tipContent={toolTip}>

        <IconButton
            aria-label="delete"
            onClick={deleteAction}
            sx={{ml: 2}}
            color={'error'}
        >
            <DeleteIcon sx={{fontSize: '2.5rem'}}/>
        </IconButton>
    </OneTwoPayTooltip>
}
export default OneTwoPayDeleteIcon;