import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, Typography} from "@mui/material";

type PropsType = {
    itemDescription: string;
    confirmAction: () => void;
    cancelAction: () => void;
}

const DeleteConfirmDialog = (props: PropsType) => {

    const {itemDescription, confirmAction, cancelAction} = props;
    const [openDialog, setOpenDialog] = useState(true);

    const deleteMessage = `Are you sure you want to delete ${itemDescription}`;


    return <Dialog
        open={openDialog}
    >
        <DialogContent>
            <Typography variant="body2" color="textSecondary">
                {deleteMessage}
            </Typography>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => {
                    setOpenDialog(false);
                    cancelAction();
                }}
                variant='contained'
                color='error'
                sx={{mr: 2}}
            >
                Cancel
            </Button>
            <Button
                variant='contained'
                onClick={confirmAction}
            >
                Yes
            </Button>
        </DialogActions>
    </Dialog>
}
export default DeleteConfirmDialog;