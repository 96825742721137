import React from 'react';
import {DataGrid, GridRowsProp, GridToolbar} from '@mui/x-data-grid';
import {makeStyles} from '@mui/styles';
import theme from "../../theme";
import {Typography} from "@mui/material";

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        margin: '0 auto',
        padding: 20,
        '& .MuiDataGrid-root': {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[4],
            '& .MuiDataGrid-columnHeader, .MuiDataGrid-overlay': {
                backgroundColor: '#e1f5fe',
                borderBottom: `1px solid ${theme.palette.divider}`,
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                    fontSize: '.9rem',
                },
            },
            '& .MuiDataGrid-row': {
                '&:nth-of-type(odd)': {
                    backgroundColor: '#f5f5f5',
                },
                '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                },
                '& .MuiDataGrid-cell': {
                    fontSize: '1.0rem',
                },
            },
            cell: {
                display: 'flex',
                alignItems: 'center',
                paddingLeft: 2,
                paddingRight: 2,
            },
        },
    },
}));

export type IncomeColumnDefinition = {
    fieldName: string,
    fieldLabel: string
}


const coreColumns = [
    {field: 'payStubId', headerName: 'Pay Stub ID', flex: 1, cellClassName: 'cell'},
    {field: 'employerName', headerName: 'Employer', flex: 1, cellClassName: 'cell'},
    {field: 'employeeName', headerName: 'Employee', flex: 1, cellClassName: 'cell'},
    {field: 'payPeriodStart', headerName: 'Period Start', flex: 1, cellClassName: 'cell'},
    {field: 'payPeriodEnd', headerName: 'Period End', flex: 1, cellClassName: 'cell'},
    {field: 'payDate', headerName: 'Pay Date', flex: 1, cellClassName: 'cell'},
];

const deductionsColumns = [
    {field: 'fedTax', headerName: 'Federal Tax', flex: 1, cellClassName: 'cell'},
    {field: 'provTax', headerName: 'Provincial Tax', flex: 1, cellClassName: 'cell'},
    {field: 'incomeTax', headerName: 'Income Tax (override)', flex: 1, cellClassName: 'cell'},
    {field: 'cpp', headerName: 'CPP', flex: 1, cellClassName: 'cell'},
    {field: 'cpp2', headerName: 'CPP 2', flex: 1, cellClassName: 'cell'},
    {field: 'ei', headerName: 'EI', flex: 1, cellClassName: 'cell'},
    {field: 'employerCpp', headerName: 'Employer CPP', flex: 1, cellClassName: 'cell'},
    {field: 'employerCpp2', headerName: 'Employer CPP 2', flex: 1, cellClassName: 'cell'},
    {field: 'employerEi', headerName: 'Employer EI', flex: 1, cellClassName: 'cell'}
];


const getUserColumns = (incomeColumns: IncomeColumnDefinition[]) => {
    let columns = [...coreColumns];
    for (const columnDef of incomeColumns) {
        const fullColumnDefinition = {
            field: columnDef.fieldName,
            headerName: columnDef.fieldLabel,
            flex: 1,
            cellClassName: 'cell'
        };
        columns.push(fullColumnDefinition);
    }
    columns.push(...deductionsColumns);
    return columns;
}


interface PayrollHistoryDataGridProps {
    rows: GridRowsProp,
    incomeColumns: IncomeColumnDefinition[];
}


const PayrollHistoryDataGrid = (props: PayrollHistoryDataGridProps) => {
    const classes = useStyles();
    const {rows, incomeColumns} = props;

    const columns = getUserColumns(incomeColumns)
    return (
        <div className={classes.root}>
            <Typography variant="h1" align="center" sx={{fontSize: '2.4rem', mb: 5}}>Payroll History</Typography>
            <DataGrid
                rows={rows}
                columns={columns}
                autoHeight
                slots={{
                    toolbar: GridToolbar
                }}
                getRowId={(row) => row.payStubId}
            />
        </div>
    );
};

export default PayrollHistoryDataGrid;