import React from 'react';
import {Typography} from "@mui/material";
import PayrollHistoryDataGrid, {IncomeColumnDefinition} from "../components/DataGrids/PayrollHistoryDataGrid";
import {Employee, PayStubWithCalculatedDeductions, useGetPayStubsQuery} from "../graphql/generated/graphql";
import LoadingErrorDisplay from "../common/LoadingErrorDisplay";

function getEmployeeName(employee: Employee) {
    let firstName = employee.firstName;
    let lastName = employee.lastName;
    return `${firstName} ${lastName}`;
}


function getIncomeColumnDefinitions(payStubData: PayStubWithCalculatedDeductions[]): IncomeColumnDefinition[] {

    return payStubData.map(payStubWithCalculatedDeduction => payStubWithCalculatedDeduction.payStubWithCalculatedIncome.incomeItems)
        .flat()
        .map(incomeItem => {
            return {
                fieldName: incomeItem.incomeName,
                fieldLabel: incomeItem.incomeLabel
            };
        })
        // filter out the duplicates
        .filter((item, index, self) =>
                index === self.findIndex((t) => (
                    t.fieldName === item.fieldName && t.fieldLabel === item.fieldLabel
                ))
        );
}

const PayrollHistory = () => {
    const {
        loading,
        error,
        data
    } = useGetPayStubsQuery();

    const payStubData = data && data.payStubs ? data.payStubs : [];

    if (!!error || loading) {
        return <LoadingErrorDisplay
            loading={loading}
            apolloError={error}
        />
    }

    if (!payStubData || payStubData.length === 0) {
        return <Typography align="center">No pay stubs found.</Typography>;
    }

    const payStubs: Array<any> = payStubData.map(payStubWithCalculatedDeductions => {
        let incomeObj: { [key: string]: string } = {};
        for (let income of payStubWithCalculatedDeductions.payStubWithCalculatedIncome.incomeItems) {
            incomeObj[income.incomeName] = income.amount;
        }

        return {
            ...payStubWithCalculatedDeductions.payStubWithCalculatedIncome,
            ...payStubWithCalculatedDeductions.calculatedDeductions,
            employerName: payStubWithCalculatedDeductions.payStubWithCalculatedIncome.employer.name,
            employeeName: getEmployeeName(payStubWithCalculatedDeductions.payStubWithCalculatedIncome.employee),
            ...incomeObj
        };
    });
    const incomeColumns = getIncomeColumnDefinitions(payStubData);

    return (
        <PayrollHistoryDataGrid
            rows={payStubs}
            incomeColumns={incomeColumns}
        />
    );
};

export default PayrollHistory;