import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Employer} from "../../graphql/generated/graphql";
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import EmployerDisplay from "./EmployerDisplay";
import EditEmployerForm from "./EditEmployerForm";

type EmployerAutoComplete = {
    label: string;
    employer: Employer
}

const employersAreEqual = (option: EmployerAutoComplete, value: EmployerAutoComplete) => {
    if (!value) {
        return false;
    }
    return option.employer.id === value.employer.id;
}

const getEmployerAutoComplete = (employer: Employer): EmployerAutoComplete => {
    return {
        label: employer.detailedName,
        employer: employer
    }
}
const getEmployerAutoCompleteOrUndefined = (employer: Employer | undefined): EmployerAutoComplete | undefined => {
    if (!employer) {
        return {
            label: '',
            employer: getEmptyEmployer()
        };
    }
    return getEmployerAutoComplete(employer);
}
const getEmployerOptions = (employers: Array<Employer>): ReadonlyArray<EmployerAutoComplete> => {
    let employerOptions = employers.map(employer => {
        return getEmployerAutoComplete(employer);
        }
    );
    const newEmployerOption: EmployerAutoComplete = {label: "Add A New Employer +", employer: getEmptyEmployer()};
    employerOptions.push(newEmployerOption);
    return employerOptions;
}
export const getEmptyEmployer = (): Employer => {
    return {
        id: 0,
        name: "",
        detailedName: "",
        address1: undefined,
        address2: undefined,
        city: undefined,
        postalCode: undefined,
        province: "ON",
    }
}

type PropsType = {
    close: () => void,
    employer: Employer | undefined,
    employerUpdated: (employerId: string) => void,
    employers: Array<Employer>;
    isEditable: boolean;
    clearEmployer: () => void;
}


const EmployerSelectEdit = (props: PropsType) => {

    const {close, employer, employerUpdated, employers, isEditable, clearEmployer} = props;
    const [editEmployer, setEditEmployer] = useState<boolean>(false);


    const selectEmployer = (newValue: EmployerAutoComplete, event: any) => {
        console.log("employer selected for: ", event.currentTarget.innerText);
        if (newValue.employer.id === 0) {
            addNewEmployer();
        } else {
            let employerId = newValue.employer.id.toString();
            employerUpdated(employerId);
            close();
        }
    }

    let autocompleteOptions: ReadonlyArray<EmployerAutoComplete> = [];
    if (employers.length > 0) {
        autocompleteOptions = getEmployerOptions(employers);
    }

    useEffect(() => {
        if (employers.length < 1) {
            setEditEmployer(true);
        }
    }, [employers]);

    const noEmployerDisplay: ReactJSXElement = <Typography>Please select an employer or create a new one.</Typography>;
    const displayEmployer: ReactJSXElement =
        <EmployerDisplay
            employer={employer}
            edit={() => setEditEmployer(true)}
            noEmployerDisplay={noEmployerDisplay}
            isEditable={isEditable}
        />;


    let dialogContentElement = displayEmployer;
    if (editEmployer) {
        dialogContentElement = <EditEmployerForm
            close={close}
            employer={!!employer ? employer : getEmptyEmployer()}
            employerUpdated={employerUpdated}
        />;
    }

    const addNewEmployer = () => {
        clearEmployer();
        setEditEmployer(true);
    }

    let employerSelectElement = <>
        <Grid container alignItems="center" sx={{mt: 2}}>
            <Grid item xs={12}>
                <Autocomplete
                    disabled={editEmployer}
                    disablePortal
                    options={autocompleteOptions}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select Employer"
                            fullWidth
                            InputLabelProps={{
                                style: {fontSize: '1.6rem'},
                            }}
                            inputProps={{
                                ...params.inputProps,
                                style: {fontSize: '1.4rem'},
                            }}
                            sx={{
                                mt: 3,
                                '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                                    backgroundColor: 'white',
                                    paddingRight: '5px',
                                },
                            }}
                        />
                    )}
                    value={getEmployerAutoCompleteOrUndefined(employer)}
                    isOptionEqualToValue={employersAreEqual}
                    getOptionLabel={(option) => option.label}
                    onChange={(event: any, newValue: any) => selectEmployer(newValue, event)}
                    renderOption={(props, option) => (
                        <li {...props} style={{fontSize: '1.4rem'}}>
                            {option.label}
                        </li>
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                {!editEmployer && <Button
                    onClick={() => addNewEmployer()}
                    variant='contained'
                    size={"large"}
                    sx={{mt: 3}}
                >
                    Add New Employer
                </Button>
                }
            </Grid>
        </Grid>
        <Divider sx={{mt: 2, mb: 0}}/></>;


    return <Dialog open={true} fullWidth={true}
                   sx={{
                       mt: 5,
                       '& .MuiDialog-paper': {
                           minWidth: {
                               xs: '95vw',
                               sm: '80vw',
                               md: '65vw',
                               lg: '20vw'
                           },
                       },
                   }}>
        <DialogTitle>
            {
                !!autocompleteOptions && autocompleteOptions.length > 0 &&
                employerSelectElement
            }
            <IconButton
                edge="end"
                color="inherit"
                onClick={close}
                aria-label="close"
                sx={{
                    position: 'absolute',
                    top: 1,
                    right: 15,
                }}
            >
                <CloseIcon fontSize={"large"}/>
            </IconButton>
        </DialogTitle>
        <DialogContent>
            {dialogContentElement}
        </DialogContent>
    </Dialog>
}

export default EmployerSelectEdit;