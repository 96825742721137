import * as React from 'react';
import {ReactNode, useEffect, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import LoadingErrorDisplay from "../common/LoadingErrorDisplay";

type PropsType = {
    children: ReactNode;
}
const SilentLogin = (props: PropsType) => {

    const {getAccessTokenSilently} = useAuth0();
    const [silentAuthDone, setSilentAuthDone] = useState<boolean>(false);


    useEffect(() => {
        // The call to getAccessTokenSilently() NEEDS TO STAY UNCONDITIONAL. It should always get called, especially when isAuthenticated is false.
        // This is because "isAuthenticated" does not resolve correctly until after getAccessTokenSilently is called.
        // This just triggers auth0 to check the session. We don't care about the result.
        // Token management is done in the OneTwoPayApolloProvider. Any issues with it are handled there.
        getAccessTokenSilently()
            .then(() => {
                setSilentAuthDone(true);
            })
            .catch(() => {
                setSilentAuthDone(true);
            });
    }, [getAccessTokenSilently]);

    if (!silentAuthDone) {
        return <LoadingErrorDisplay loading={!silentAuthDone}/>
    }

    return <>
        {props.children}
    </>
}
export default SilentLogin;