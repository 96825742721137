import {getNumber} from "./stringMath";

const CURRENCY_FORMAT = new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
});

export function getCurrencyFormatFromNullableStringOrNumber(amount: number | string | undefined | null) {
    if (!amount) {
        return "";
    }
    let amountAsNumber = getNumber(amount);
    return CURRENCY_FORMAT.format(amountAsNumber);
}

