import React from 'react';
import Header from "../Header";
import Footer from "../Footer";
import Box from '@mui/material/Box';
import AnnouncementBar from "../AnnouncementBar";
import {APP_LINK_CREATE} from "../../common/AppLinks";

const HEADER_HEIGHT = "164px";
const FOOTER_HEIGHT = "156px";

const Layout = (props: any) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            minHeight="100vh"
        >
            <AnnouncementBar
                url={`${APP_LINK_CREATE}`}
                title="🔔 Welcome to OneTwoPay’s new pay stub generator. 🔔"
                linkText=" - Click here to use the original."
            />
            <Box height={HEADER_HEIGHT}>
                <Header/>
            </Box>
            <Box
                component="main"
                flexGrow={1}
                minHeight={`calc(100vh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT})`}
            >
                {props.children}
            </Box>

            <Box height={FOOTER_HEIGHT}>
                <Footer/>
            </Box>
        </Box>
    );
}

export default Layout;