import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Employee, useGetEmployeesLazyQuery} from "../../graphql/generated/graphql";
import useAnonCookie from "../../security/useAnonCookie";
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import EditEmployeeForm from "./EditEmployeeForm";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import EmployeeDisplay from "./EmployeeDisplay";
import useSystemNotices from "../../Utils/useSystemNotices";

type EmployeeAutoComplete = {
    label: string;
    employee: Employee
}

const getEmployeeAutoComplete = (employee: Employee): EmployeeAutoComplete => {
    return {
        label: `${employee.detailedName}`,
        employee: employee
    }
}
const getEmployeeOptions = (employees: Array<Employee>): ReadonlyArray<EmployeeAutoComplete> => {
    let employeeOptions = employees
        .filter(employee => !employee.deleted)
        .map(employee => {
                return getEmployeeAutoComplete(employee);
            }
        );
    const newEmployeeOption: EmployeeAutoComplete = {label: "Add A New Employee + ", employee: getEmptyEmployee()};
    employeeOptions.push(newEmployeeOption);
    return employeeOptions;
}
export const getEmptyEmployee = (): Employee => {
    return {
        id: 0,
        firstName: "",
        lastName: "",
        detailedName: "",
        address1: undefined,
        address2: undefined,
        city: undefined,
        companyUserId: undefined,
        postalCode: undefined,
        province: "ON",
        eiExempt: false,
        cppExempt: false,
        federalClaimCode: "CLAIM_CODE_1",
        provincialClaimCode: "CLAIM_CODE_1",
        employerEiRatio: "1.4",
        payFrequency: "BI_WEEKLY",
        employmentProvince: "ON",
        deleted: false
    }
}
const getEmployeeOrDefault = (employee: Employee | undefined): Employee => {
    if (!!employee && employee.id > 0) {
        return employee;
    }
    return getEmptyEmployee();
}

type PropsType = {
    close: () => void,
    employee: Employee | undefined,
    employeeAddedEdited: (employee: Employee) => void,
    employeeSelected: (employee: Employee) => void;
    hasPayStubs: boolean;
    isEditable: boolean;
}


const EmployeeSelectEdit = (props: PropsType) => {
    const {close, employee, employeeAddedEdited, employeeSelected, isEditable} = props;
    const [editMode, setEditMode] = useState<boolean>(false);
    const [employeeOrNew, setEmployeeOrNew] = useState<Employee>(getEmployeeOrDefault(employee));
    const [hasPayStubs, setHasPayStubs] = useState<boolean>(props.hasPayStubs);
    const {getAnonUserId} = useAnonCookie();
    const {sendNotice} = useSystemNotices();

    const [
        getEmployees,
        {
            data: employeesData,
            loading: employeesLoading,
            error: employeesError
        }] = useGetEmployeesLazyQuery({variables: {anonUserId: getAnonUserId()}});

    const selectEmployee = (newValue: EmployeeAutoComplete, event: any) => {
        console.log("employee selected for: ", event.currentTarget.innerText);
        if (newValue.employee.id === 0) {
            newEmployee();
        } else {
            employeeSelected(newValue.employee);
            close();
        }
    }

    const newEmployee = () => {
        setHasPayStubs(false);
        setEditMode(true);
        setEmployeeOrNew(getEmptyEmployee());
    }

    let autocompleteOptions: ReadonlyArray<EmployeeAutoComplete> = [];
    if (!!employeesData && !!employeesData.getEmployees && employeesData.getEmployees.length > 0) {
        autocompleteOptions = getEmployeeOptions(employeesData?.getEmployees);
    }

    useEffect(() => {
        if (!employeesData && !employeesLoading && !employeesError) {
            getEmployees()
                .then(result => {
                    if (!result.data) {
                        sendNotice("Error getting employees in Employee select. getEmployees did not return any data.");
                    }
                })
                .catch(error => {
                    sendNotice(`Error getting employees: ${error.message}`);
                });
        }
    }, [getEmployees, sendNotice, employeesData, employeesLoading, employeesError]);

    useEffect(() => {
        if (!!employeesData && employeesData.getEmployees.length < 1) {
            newEmployee();
        }
    }, [employeesData]);

    if (employeesLoading) {
        return <Typography>Loading employees</Typography>;
    }

    const noEmployeeDisplay: ReactJSXElement = <Typography>Please select an employee or create a new one.</Typography>;
    const displayEmployee: ReactJSXElement = <EmployeeDisplay
        employee={employeeOrNew}
        edit={() => setEditMode(true)}
        noEmployeeDisplay={noEmployeeDisplay}
        isEditable={isEditable}
    />;

    const editEmployee: ReactJSXElement = <EditEmployeeForm
        close={close}
        employee={employeeOrNew}
        employeeAddedEdited={employeeAddedEdited}
        hasPayStubs={hasPayStubs}
    />;

    const dialogContentElement = editMode ? editEmployee : displayEmployee;

    return <Dialog
        open={true}
        fullWidth={true}
        sx={{
            mt: 15,
            '& .MuiDialog-paper': {
                minWidth: {xs: 'lg', sm: 'lg', md: 'md'},
            },
        }}
    >
        <DialogTitle>
            <Grid container alignItems="center" sx={{mt: 2}}>
                {editMode &&
                    <Typography>Add / Edit Employee</Typography>
                }
                {!editMode &&
                    <Grid item xs={12}>
                        <Autocomplete
                            disabled={editMode}
                            disablePortal
                            options={autocompleteOptions}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Employee"
                                    fullWidth
                                    InputLabelProps={{
                                        style: {fontSize: '1.6rem'},
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        style: {fontSize: '1.4rem'},
                                    }}
                                    sx={{
                                        mt: 3,
                                        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                                            backgroundColor: 'white',
                                            paddingRight: '5px',
                                        },
                                    }}
                                />
                            )}
                            getOptionLabel={(option) => option.label}
                            renderOption={(props, option) => (
                                <li {...props} style={{fontSize: '1.4rem'}}>
                                    {option.label}
                                </li>
                            )}
                            value={getEmployeeAutoComplete(employeeOrNew)}
                            isOptionEqualToValue={(option, value) => option.employee.id === value.employee.id}
                            onChange={(event: any, newValue: any) => {
                                if (!!newValue) {
                                    selectEmployee(newValue, event);
                                }
                            }}
                        />
                    </Grid>
                }
                <Grid item xs={12} sm={6}>
                    {!editMode && <Button
                        onClick={() => newEmployee()}
                        variant='contained'
                        size={"large"}
                        fullWidth={true}
                        sx={{mt: 3}}
                    >
                        Add An Employee
                    </Button>
                    }
                </Grid>
            </Grid>
            <Divider sx={{mt: 2, mb: 0}}/>
            <IconButton
                edge="end"
                color="inherit"
                onClick={close}
                aria-label="close"
                sx={{
                    position: 'absolute',
                    top: 2,
                    right: 15,
                }}
            >
                <CloseIcon fontSize={"large"}/>
            </IconButton>
        </DialogTitle>
        <DialogContent>
            {dialogContentElement}
        </DialogContent>
    </Dialog>
}

export default EmployeeSelectEdit;