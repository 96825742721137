import {useMediaQuery, useTheme} from "@mui/material";


const useViewerStyle = () => {

    const theme = useTheme();
    const isScreenWide = useMediaQuery(theme.breakpoints.up('sm'));


    const getPdfViewStyle = () => {
        return {
            display: 'flex',
            justifyContent: isScreenWide ? 'center' : 'flex-start',
            alignItems: 'center',
            height: '100%'
        };
    }

    return {
        isScreenWide,
        getPdfViewStyle
    }
}

export default useViewerStyle;