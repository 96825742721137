import React, {ReactNode} from "react";

type PropsType = {
    children: ReactNode;
};


type StateType = {
    hasError: boolean;
};

class ErrorBoundary extends React.Component<PropsType, StateType> {
    constructor(props: PropsType) {
        super(props);
        this.state = {hasError: false};
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        this.setState({hasError: true});
        console.log("found an error in the error boundary");
        console.log(error, info);
    }

    render() {
        if (this.state.hasError) {
            return <h2>Oops. Something went wrong. Please try again or contact support.</h2>;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;