import React, {useState} from "react";
import {Box, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, Typography} from "@mui/material";
import {useForm} from "react-hook-form";
import {RoeTextField} from "./RoeForm";

export type EmployeeDetailsType = {
    employeeFirstName: string;
    employeeMiddleInitial: string;
    employeeLastName: string;
    employeeAddress: string;
    employeeCity: string;
    employeeProvinceAndCountry: string;
    employeePostalCode: string;
    employeeOccupation: string;
    socialInsuranceNumber: string;
}


type PropsType = {
    employeeDetails: EmployeeDetailsType
    close: () => void;
    updateEmployeeDetails: (employeeDetails: EmployeeDetailsType) => void;
}

const RoeEmployeeDetailsForm = (props: PropsType) => {
    const {
        employeeDetails,
        close,
        updateEmployeeDetails
    } = props;

    const [showCloseWarning, setShowCloseWarning] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<EmployeeDetailsType>({
        defaultValues: employeeDetails,
    });


    return <form noValidate onSubmit={handleSubmit(updateEmployeeDetails)}>
        <Box sx={{height: "100%", m: 2, p: 1}}>
            <Grid container alignItems="center">
                <Grid container spacing={2} justifyContent={"start"} sx={{ml: .25}}>
                    <Grid item xs={12}>
                        <Typography variant={"h4"} sx={{pb: 1.5}}>
                            Please review the employee details and update as needed.
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <RoeTextField
                            fullWidth
                            label={"Employee first name"}
                            {...register("employeeFirstName", {required: "First name is required"})}
                            error={!!errors.employeeFirstName}
                            helperText={errors.employeeFirstName?.message}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <RoeTextField
                            fullWidth
                            label={"Employee middle initial"}
                            {...register("employeeMiddleInitial")}
                            error={!!errors.employeeMiddleInitial}
                            helperText={errors.employeeMiddleInitial?.message}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <RoeTextField
                            fullWidth
                            label={"Employee last name"}
                            {...register("employeeLastName", {required: "Last name is required"})}
                            error={!!errors.employeeLastName}
                            helperText={errors.employeeLastName?.message}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <RoeTextField
                            fullWidth
                            label={"Employee address"}
                            {...register("employeeAddress", {required: "Address is required"})}
                            error={!!errors.employeeAddress}
                            helperText={errors.employeeAddress?.message}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <RoeTextField
                            fullWidth
                            label={"Employee city / place"}
                            {...register("employeeCity", {required: "City / place is required"})}
                            error={!!errors.employeeCity}
                            helperText={errors.employeeCity?.message}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <RoeTextField
                            fullWidth
                            label={"Employee province / country"}
                            {...register("employeeProvinceAndCountry", {required: "Province / country is required"})}
                            error={!!errors.employeeProvinceAndCountry}
                            helperText={errors.employeeProvinceAndCountry?.message}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <RoeTextField
                            fullWidth
                            label={"Employee postal code"}
                            {...register("employeePostalCode", {required: "Postal code is required"})}
                            error={!!errors.employeePostalCode}
                            helperText={errors.employeePostalCode?.message}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <RoeTextField
                            fullWidth
                            label={"Employee occupation"}
                            {...register("employeeOccupation")}
                            error={!!errors.employeeOccupation}
                            helperText={errors.employeeOccupation?.message}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <RoeTextField
                            fullWidth
                            label={"Social Insurance Number"}
                            {...register("socialInsuranceNumber", {required: "Social Insurance Number required"})}
                            error={!!errors.socialInsuranceNumber}
                            helperText={errors.socialInsuranceNumber?.message}
                        />
                    </Grid>
                    <Divider sx={{borderColor: 'grey', borderWidth: '.5px', width: '100%', my: 2}}/>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button
                                size={"large"}
                                variant="contained"
                                color="error"
                                onClick={() => setShowCloseWarning(true)}
                                style={{fontSize: '1.4rem', marginLeft: 20, marginRight: '10px'}}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                size={"large"}
                                variant="contained"
                                type={"submit"}
                                style={{fontSize: '1.4rem'}}
                            >
                                Next Step
                            </Button>
                        </Grid>
                        {showCloseWarning && (

                            <Dialog
                                open={showCloseWarning}
                            >
                                <DialogTitle>
                                    Confirm cancel
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography
                                            sx={{fontSize: "large"}}
                                            >
                                                Are you sure you would like to cancel. All changes will be lost.
                                            </Typography>
                                        </Grid>
                                        <Grid item
                                              sx={{mt: 3}}
                                              xs={12}
                                        >
                                            <Button
                                                sx={{mr: 4}}
                                                variant={"contained"}
                                                onClick={() => setShowCloseWarning(false)}
                                                color="error"
                                            >
                                                No. I don't want to cancel.
                                            </Button>
                                            <Button
                                                variant={"contained"}
                                                onClick={close}
                                            >
                                                Yes. I'm, ok with that.
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                            </Dialog>

                        )

                        }
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    </form>
}

export default RoeEmployeeDetailsForm;