import {useLocation, useNavigate} from "react-router-dom";
import {useCallback} from "react";
import {useAuth0} from "@auth0/auth0-react";
import useSystemNotices from "../../Utils/useSystemNotices";
import usePayStubIdManager from "../PayStub/usePayStubIdManager";

export type ActionButton = {
    label: string;
    action: () => void;
}

type PrimaryButtonType = {
    getPrimaryButtonDefinition: () => ActionButton;
}

const usePrimaryButton = (): PrimaryButtonType => {
    const location = useLocation();
    const navigate = useNavigate();
    const {isAuthenticated, loginWithPopup} = useAuth0();
    const {sendNotice} = useSystemNotices();
    const {getPayStubId} = usePayStubIdManager();

    const onCreatePayStubPage = !!location.pathname && location.pathname.startsWith("/pay-stub");

    const goToNewPayStub = useCallback(() => {
        navigate('/pay-stub');
    }, [navigate]);


    const createPayStub: ActionButton = {
        action: goToNewPayStub,
        label: "Create a pay stub"
    }

    const createNewPayStub: ActionButton = {
        action: goToNewPayStub,
        label: "Create a new pay stub"
    }

    const signUpAction = () => {
        loginWithPopup({
            authorizationParams: {
                screen_hint: 'signup',
            }
        })
            .catch(error => sendNotice(`Error creating account for user on pay stub page: ${error.message}. Pay stub ID: ${getPayStubId()}`));
    }

    const createAnAccount: ActionButton = {
        action: signUpAction,
        label: "Create an account"
    }


    const getPrimaryButton = (): ActionButton => {
        let primaryAction = createPayStub;
        if (onCreatePayStubPage) {
            primaryAction = createAnAccount;
            if (isAuthenticated) {
                primaryAction = createNewPayStub;
            }
        }
        return primaryAction;
    }

    return {
        getPrimaryButtonDefinition: getPrimaryButton
    }
}
export default usePrimaryButton;