import React, {ReactElement, ReactNode, useCallback} from "react";
import EditIcon from "@mui/icons-material/Edit";
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OneTwoPayTooltip from "./OneTwoPayTooltip";

type PropsType = {
    dialogTitle: ReactNode;
    children: ReactNode;
    toolTipText: string;
    openDialog: boolean;
    setOpenDialog: (openDialog: boolean) => void;
    triggerCancelEditAction?: () => void;
    editIcon?: ReactElement;
}

const EditDialog = (props: PropsType) => {
    const {toolTipText, dialogTitle, children, openDialog, setOpenDialog, editIcon, triggerCancelEditAction} = props;

    const getEditIcon = useCallback((): ReactElement => {

        if (!!editIcon) {
            return editIcon;
        }
        return <EditIcon
            onClick={() => {
                setOpenDialog(true)
            }}
            color={"primary"}
            data-testid={"edit-income-item-icon"}
        />
    }, [setOpenDialog, editIcon]);
    return <>
        <OneTwoPayTooltip
            tipContent={toolTipText}
        >
            {getEditIcon()}
        </OneTwoPayTooltip>
        <Dialog
            fullWidth
            maxWidth={"md"}
            open={openDialog}
        >
            <DialogTitle sx={{ml: 2}}>
                {dialogTitle}
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => {
                        !!triggerCancelEditAction && triggerCancelEditAction();
                        setOpenDialog(false);
                    }}
                    aria-label="close"
                    sx={{
                        position: 'absolute',
                        top: 1,
                        right: 15,
                    }}
                >
                    <CloseIcon fontSize={"large"}/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>
    </>
}
export default EditDialog;