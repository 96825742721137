import React, {useState} from "react";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Typography
} from "@mui/material";
import {InsurableEarnings, PayPeriod} from "../../../graphql/generated/graphql";
import {Add} from "@mui/icons-material";
import RoePayPeriod from "./RoePayPeriod";
import RoePayPeriodDisplayEdit from "./RoePayPeriodDisplayEdit";
import {makeStyles} from "@mui/styles";


const useStyles = makeStyles(() => ({
    addPeriodContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        fontSize: '0.9em',
        paddingTop: 0,
    },
    addPeriodHeader: {
        fontSize: '0.9em',
        paddingBottom: 0,
    },
    addPeriod: {
        display: 'flex',
        flexDirection: 'column',
        // height: '100%',
        height: 110,
        width: 200,
        marginTop: 0,
        paddingTop: 0
    },
    userMessageButton: {
        float: 'right'
    }
}));


type PropsType = {
    roeInsurableEarnings: InsurableEarnings;
    payPeriodDeleted: (id: string) => void;
    updatePayPeriod: (payPeriod: PayPeriod) => void;
}

const RoeInsurableEarnings = (props: PropsType) => {
    const {roeInsurableEarnings, payPeriodDeleted, updatePayPeriod} = props;
    const [addPayPeriod, setAddPayPeriod] = useState<boolean>(false);
    const [hideMessage, setHideMessage] = useState(false);

    const classes = useStyles();
    const payPeriods = roeInsurableEarnings.payPeriods;

    return <Grid container>
        {payPeriods.map(payPeriod => {
            return <Grid item xs={6} sm={4} md={3}
                         key={payPeriod.id}
                         sx={{
                             mb: 2,
                             mr: 1,
                         }}>
                <RoePayPeriodDisplayEdit
                    payPeriod={payPeriod}
                    payPeriodDeleted={payPeriodDeleted}
                    payPeriodUpdated={updatePayPeriod}
                />
            </Grid>

        })}

        <Grid item xs={12} sm={6} md={4} container direction="row" alignItems="flex-start">
            <Button
                style={{padding: 0, textTransform: 'none'}}
                onClick={() => setAddPayPeriod(true)}
            >
                <Card className={classes.addPeriod}>
                    <CardHeader
                        title="Add pay period"
                        className={classes.addPeriodHeader}
                    />
                    <CardContent className={classes.addPeriodContent}>
                        <Add sx={{fontSize: "4rem", fontWeight: "bold"}}/>
                    </CardContent>
                </Card>
            </Button>
        </Grid>
        {addPayPeriod &&
            <Grid item xs={12}>
                <RoePayPeriod
                    updatePayPeriod={updatePayPeriod}
                    close={() => {
                        setAddPayPeriod(false);
                    }}
                />
            </Grid>
        }
        {!hideMessage && !!roeInsurableEarnings.userMessage &&
            <Dialog
                open={!hideMessage}
            >
                <DialogTitle>Service Canada validation warning</DialogTitle>
                <DialogContent>
                    {roeInsurableEarnings.userMessage}
                    <Button
                        className={classes.userMessageButton}
                        variant={"contained"}
                        onClick={() => setHideMessage(true)}
                    >
                        Ok. I got it.
                    </Button>
                </DialogContent>
            </Dialog>
        }
        <Grid xs={12}>
            <Typography>* Note that Service Canada orders pay periods starting with the most recent period.</Typography>
        </Grid>
    </Grid>
}

export default RoeInsurableEarnings;