import {Deduction, DeductionType} from "../../graphql/generated/graphql";
import useSystemNotices from "../../Utils/useSystemNotices";


type ReturnType = {
    getDeductionType: (deductionTypeId: number, deductionTypes: Array<DeductionType>) => DeductionType;
    getCalculatedDeductionsWithTypes: (deductions: Array<Deduction>, deductionTypes: Array<DeductionType>) => Array<DeductionWithType>
    getCustomDeductionsWithTypes: (deductions: Array<Deduction>, deductionTypes: Array<DeductionType>) => Array<DeductionWithType>
}

export type DeductionWithType = {
    deduction: Deduction;
    deductionType: DeductionType;
}

const FIRST_CUSTOM_DEDUCTION_TYPE_ID = 11;
const useGetIncomeTypeInfo = (): ReturnType => {

    const {sendNotice} = useSystemNotices();

    const getDeductionType = (deductionTypeId: number, deductionTypes: Array<DeductionType>): DeductionType => {
        const deductionType = deductionTypes.find(deductionType => deductionType.id === deductionTypeId);
        // console.log("deductionTypeId: ", JSON.stringify(deductionTypeId, null, 2));
        // console.log("deductionTypes: ", JSON.stringify(deductionTypes, null, 2));
        if (!deductionType) {
            sendNotice("Error. Critical. ACTION REQUIRED. This should not happen. Deduction type not found for deductionTypeId: " + deductionTypeId);
            throw new Error("System error. Deduction type not found. Please try again or contact support."); // todo: need to do something else here and not throw an error.
        }
        return deductionType;
    }

    const getCalculatedDeductionsWithTypes = (deductions: Array<Deduction>, deductionTypes: Array<DeductionType>): Array<DeductionWithType> => {
        return deductions
            .filter(deduction => deduction.deductionTypeId < FIRST_CUSTOM_DEDUCTION_TYPE_ID)
            .map(deduction => {
                return {
                    deduction: deduction,
                    deductionType: getDeductionType(deduction.deductionTypeId, deductionTypes)
                }
            });
    }

    const getCustomDeductionsWithTypes = (deductions: Array<Deduction>, deductionTypes: Array<DeductionType>): Array<DeductionWithType> => {
        return deductions
            .filter(deduction => deduction.deductionTypeId >= FIRST_CUSTOM_DEDUCTION_TYPE_ID)
            .map(deduction => {
                return {
                    deduction: deduction,
                    deductionType: getDeductionType(deduction.deductionTypeId, deductionTypes)
                }
            });
    }


    return {
        getDeductionType,
        getCalculatedDeductionsWithTypes,
        getCustomDeductionsWithTypes
    }
}

export default useGetIncomeTypeInfo;