import {EmployeeWithStatus, useGetRoeInitialValuesForEmployeeQuery} from "../../../graphql/generated/graphql";
import React from "react";
import LoadingErrorDisplay from "../../../common/LoadingErrorDisplay";
import ErrorDisplay from "../../../common/ErrorDisplay";
import RoeForm from "./RoeForm";
import EditEmployee from "../../Employee/EditEmployee";
import {Card, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import useSystemNotices from "../../../Utils/useSystemNotices";

type PropsType = {
    employeeWithStatus: EmployeeWithStatus;
    close: () => void;
    reloadEmployee: () => void;
}

const RoeInput = (props: PropsType) => {

    const {employeeWithStatus, close, reloadEmployee} = props;
    const {sendNotice} = useSystemNotices();

    const {
        data: roeInitialValuesData,
        loading: roeInitialValuesLoading,
        error: roeInitialValuesError
    } = useGetRoeInitialValuesForEmployeeQuery({
        variables: {employeeId: employeeWithStatus.employee.id.toString()},
        fetchPolicy: "no-cache"
    })

    if (roeInitialValuesLoading || roeInitialValuesError) {
        return <LoadingErrorDisplay
            loading={roeInitialValuesLoading}
            apolloError={roeInitialValuesError}
        />
    }

    if (!roeInitialValuesData?.getRoeInitialValuesForEmployee) {
        return <ErrorDisplay
            stringError={`Could not load ROE data for employee: ${employeeWithStatus.employee.firstName} ${employeeWithStatus.employee.lastName}`}
        />
    }

    if (!roeInitialValuesData.getRoeInitialValuesForEmployee.hasFinalizedStubs) {
        return <ErrorDisplay
            stringError={`Could not load ROE data for employee: ${employeeWithStatus.employee.firstName} ${employeeWithStatus.employee.lastName}. No finalized pay stubs found.`}
        />
    }

    if (!roeInitialValuesData.getRoeInitialValuesForEmployee.initialValues) {
        sendNotice(`Error in RoeInput. initialValues is empty even though hasFinalizedPayStubs is true. This should not happen. Employee: ${employeeWithStatus.employee.id} - ${employeeWithStatus.employee.detailedName}`);
        return <ErrorDisplay
            stringError={`Could not load ROE data for employee: ${employeeWithStatus.employee.firstName} ${employeeWithStatus.employee.lastName}. No data found.`}
        />
    }

    const recommendedActions = employeeWithStatus.recommendedActions;
    if (!!recommendedActions && recommendedActions.length > 0) {
        return <>
            <Card>
                <Box padding={2}>
                    <Typography
                        sx={{fontSize: "large"}}
                    >
                        The selected employee record requires editing before it can be used to generate an ROE
                    </Typography>
                    <EditEmployee
                        close={close}
                        employeeId={employeeWithStatus.employee.id}
                        employeeAddedEdited={reloadEmployee}
                        recommendedActions={recommendedActions}
                    />
                </Box>
            </Card>
        </>
    }
    return !!roeInitialValuesData && !!roeInitialValuesData.getRoeInitialValuesForEmployee && (
        <RoeForm
            roeInitialValues={roeInitialValuesData.getRoeInitialValuesForEmployee.initialValues}
            close={close}
        />
    );
}

export default RoeInput;