import React, {useCallback, useState} from 'react';
import {DataGrid, GridCellParams, GridColDef, GridRowsProp} from '@mui/x-data-grid';
import {makeStyles} from '@mui/styles';
import theme from "../../theme";
import {Typography} from "@mui/material";
import {QuickSearchToolbar} from "../Report/EmployeesSelect";
import ResponsiveEditButton from "../ResponsiveEditButton";
import {useNavigate} from "react-router-dom";
import OneTwoPayDeleteIcon from "../../common/OneTwoPayDeleteIcon";
import {useDeletePayStubMutation} from "../../graphql/generated/graphql";
import useAnonCookie from "../../security/useAnonCookie";
import useSystemNotices from "../../Utils/useSystemNotices";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import SuccessMessageDisplay from "../../common/SuccessMessageDisplay";
import DeleteConfirmDialog from "../../common/DeleteConfirmDialog";

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        margin: '0 auto',
        '& .MuiDataGrid-root': {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[4],
            '& .MuiDataGrid-columnHeader, .MuiDataGrid-overlay': {
                backgroundColor: '#e1f5fe',
                borderBottom: `1px solid ${theme.palette.divider}`,
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                    fontSize: '.9rem',
                },
            },
            '& .MuiDataGrid-row': {
                '&:nth-of-type(odd)': {
                    backgroundColor: '#f5f5f5',
                },
                '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                },
                '& .MuiDataGrid-cell': {
                    fontSize: '1.1rem',
                },
            },
            cell: {
                display: 'flex',
                alignItems: 'center',
                paddingLeft: 2,
                paddingRight: 2,
            },
        },
    },
}));

type PayStubOptionRow = {
    id: number;
    payDate: string;
    payPeriod: string;
    finalized: boolean;
}

type MyColDef = GridColDef & {
    field: 'id' | 'payPeriod' | 'payDate' | 'finalized' | 'open' | 'delete',
}

interface MyGridCellParams extends GridCellParams {
    row: PayStubOptionRow;
}

const getColumns = (openPayStub: (payStubId: number) => void, deletePayStub: (payStubId: number) => void): MyColDef[] => {
    return [
        {field: 'id', headerName: 'Pay stub ID', flex: 1, maxWidth: 100, cellClassName: 'cell'},
        {field: 'payPeriod', headerName: 'Pay period', maxWidth: 200, flex: 1, cellClassName: 'cell'},
        {field: 'payDate', headerName: 'Pay date', maxWidth: 100, flex: 1, cellClassName: 'cell'},
        {
            field: 'finalized', headerName: 'Finalized', maxWidth: 75, flex: 1, cellClassName: 'cell',
            renderCell: (params: MyGridCellParams) => {
                return <Typography
                    style={{paddingTop: '15px', paddingLeft: '5px'}}
                >
                    {params.row.finalized ? "Yes" : "No"}
                </Typography>;
            }
        },
        {
            field: 'open',
            headerName: '',
            flex: .5,
            cellClassName: 'cell',
            renderCell: (params: MyGridCellParams) => {
                return <ResponsiveEditButton
                    buttonLabel={"View pay stub"}
                    editAction={event => {
                        event.stopPropagation();
                        openPayStub(params.row.id);
                    }}
                />;
            }
        },
        {
            field: 'delete',
            headerName: '',
            flex: .5,
            cellClassName: 'cell',
            renderCell: (params: MyGridCellParams) => {
                return <OneTwoPayDeleteIcon
                    toolTip={'Delete pay stub'}
                    deleteAction={() => deletePayStub(params.row.id)}
                />
            }
        },
    ];
}


interface SimplePayStubsListProps {
    rows: GridRowsProp,
    employeeName: string,
    reload: () => void;
}


const SimplePayStubList = (props: SimplePayStubsListProps) => {
    const classes = useStyles();
    const {rows, employeeName, reload} = props;
    const navigate = useNavigate();
    const {getAnonUserId} = useAnonCookie();
    const {sendNotice} = useSystemNotices();
    const [error, setError] = useState<string | null | undefined>();
    const [deleteSuccessMessage, setDeleteSuccessMessage] = useState<string | null | undefined>();
    const [deletePayStubId, setDeletePayStubId] = useState<number>();

    const [
        deletePayStub,
        {
            loading: deleteLoading,
            error: deleteError
        }
    ] = useDeletePayStubMutation();

    const goToPayStub = useCallback((payStubId: number) => {
        navigate(`/pay-stub/${payStubId}`);
    }, [navigate]);

    const deletePayStubOnServer = useCallback((payStubId: number) => {
        deletePayStub({
            variables: {
                payStubId: payStubId,
                anonUserId: getAnonUserId()
            },
        })
            .then(result => {
                if (!result.data?.deletePayStub.successful) {
                    sendNotice(`Error deleting pay stub: ${result.data?.deletePayStub.message}`);
                    setError(result.data?.deletePayStub.message);
                } else {
                    setDeleteSuccessMessage("Pay stub deleted. It will no longer be included in reports or search results.");
                    reload();
                }
                setDeletePayStubId(undefined);
            })
            .catch(error => {
                sendNotice(`Error deleting pay stub: ${error.message}`);
                setError("Error deleting pay stub. Please try again or contact support.");
                setDeletePayStubId(undefined);
            });
    }, [deletePayStub, getAnonUserId, sendNotice, reload]);


    return (
        <div className={classes.root}>
            <Typography variant="h1" align="center" sx={{fontSize: '2.4rem', mb: 5}}>
                Pay stubs for: {employeeName}
            </Typography>
            <DataGrid
                slots={{toolbar: QuickSearchToolbar}}
                rows={rows}
                columns={getColumns(goToPayStub, setDeletePayStubId)}
                autoHeight
            />
            {
                (deleteLoading || !!deleteError || !!error) &&
                <LoadingErrorDisplay
                    loading={deleteLoading}
                    apolloError={deleteError}
                    stringError={error}
                />
            }
            {
                !!deleteSuccessMessage &&
                <SuccessMessageDisplay messages={deleteSuccessMessage}/>
            }
            {
                !!deletePayStubId &&
                <DeleteConfirmDialog
                    itemDescription={`pay stub ${deletePayStubId}`}
                    confirmAction={() => deletePayStubOnServer(deletePayStubId)}
                    cancelAction={() => setDeletePayStubId(undefined)}
                />
            }
        </div>
    );
};

export default SimplePayStubList;