import {Deduction, DeductionType} from "../../graphql/generated/graphql";
import React from "react";
import {Grid, TableCell, Typography} from "@mui/material";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import {getCurrencyFormatFromNullableStringOrNumber} from "../../Utils/currencyFormatter";

type PropsType = {
    deduction: Deduction,
    deductionType: DeductionType | undefined,
    editIconDialog?: JSX.Element
}

function getCustomDeductionTypeDisplay(deductionType: DeductionType | undefined) {
    if (!deductionType) {
        return <LoadingErrorDisplay
            loading={true}
        />
    }
    return <Typography sx={{mr: 2}}>
        {deductionType.label}
    </Typography>;
}

const DeductionDisplay = (props: PropsType) => {
    const {deduction, deductionType, editIconDialog} = props;
    return <>
        <TableCell>
            <Grid container direction="row" alignItems="center">
                {getCustomDeductionTypeDisplay(deductionType)}
                {editIconDialog}
            </Grid>

        </TableCell>
        <TableCell>
            <Typography sx={{fontSize: '1.4rem'}}>
                {getCurrencyFormatFromNullableStringOrNumber(deduction.amount)}
            </Typography>
        </TableCell>
        <TableCell>
            <Typography sx={{fontSize: '1.4rem'}}>
                {getCurrencyFormatFromNullableStringOrNumber(deduction.ytdAmount)}
            </Typography>
        </TableCell>
    </>
}
export default DeductionDisplay;