import React, {useState} from "react";
import {DataGrid, GridRowsProp} from "@mui/x-data-grid";
import {Employer, useGetEmployersQuery} from "../graphql/generated/graphql";
import LoadingErrorDisplay from "../common/LoadingErrorDisplay";
import useAnonCookie from "../security/useAnonCookie";
import EditEmployerDialog from "../components/Employer/EditEmployerDialog";
import {StyledPaper} from "../components/PayStub/payStubStyles";
import {useParams} from "react-router-dom";
import ResponsiveEditButton from "../components/ResponsiveEditButton";


function getEmployer(editEmployerId: number, employers: Array<Employer>): Employer {
    return employers.find(employer => employer.id === editEmployerId) as Employer;
}

type ParamsType = {
    employerId: string;
}

const Employers = () => {


    const {employerId} = useParams<ParamsType>();
    const {getAnonUserId} = useAnonCookie();


    let initialEmployerIdToEdit = !!employerId ? employerId : undefined;
    const [editEmployerId, setEditEmployerId] = useState<string | undefined>(initialEmployerIdToEdit);

    const {
        data: employerData,
        loading: employerLoading,
        error: employersError
    } = useGetEmployersQuery({variables: {anonUserId: getAnonUserId()}});


    if (!!editEmployerId && !!employerData?.getEmployers) {
        return <EditEmployerDialog
            close={() => setEditEmployerId(undefined)}
            employer={getEmployer(parseInt(editEmployerId), employerData.getEmployers)}
        />
    }

    const getEmployerColumns = () => {

        return [
            {field: 'id', headerName: 'Employer ID', flex: 1, cellClassName: 'cell'},
            {field: 'name', headerName: 'Employer Name', flex: 1, cellClassName: 'cell'},
            {field: 'address1', headerName: 'Address', flex: 1, cellClassName: 'cell'},
            {field: 'address2', headerName: 'Address continued', flex: 1, cellClassName: 'cell'},
            {field: 'city', headerName: 'City', flex: 1, cellClassName: 'cell'},
            {field: 'province', headerName: 'Province', flex: 1, cellClassName: 'cell'},
            {field: 'postalCode', headerName: 'Postal code', flex: 1, cellClassName: 'cell'},
            {
                field: 'complete',
                headerName: 'Edit',
                flex: .5,
                cellClassName: 'cell',
                renderCell: (params: { id: any; }) => {
                    return <ResponsiveEditButton
                        buttonLabel={"Edit Employer"}
                        editAction={event => {
                            event.stopPropagation();
                            setEditEmployerId(params.id);
                        }}
                    />;
                }
            },
        ];
    }


    if (employerLoading || employersError) {
        return <LoadingErrorDisplay
            loading={employerLoading}
            apolloError={employersError}
        />
    }

    const rows: GridRowsProp = employerData ? employerData.getEmployers : [];


    return <StyledPaper
        elevation={3}
    >
        <DataGrid
            columns={getEmployerColumns()}
            rows={rows}/>
    </StyledPaper>

}

export default Employers;