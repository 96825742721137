import {Table, TableBody, TableRow} from "@mui/material";
import React from "react";
import useGetDeductionTypeInfo, {DeductionWithType} from "./useGetDeductionTypeInfo";
import {ValidationResult} from "./IncomeItemEdit";
import DeductionDisplayEdit from "./DeductionDisplayEdit";
import {DeductionType} from "../../graphql/generated/graphql";

type PropsType = {
    deductionsWithTypes: Array<DeductionWithType>;
    deductionTypes: Array<DeductionType>;
    payStubId: number | undefined;
    isEditable: boolean;
    deductionsUpdated: () => void;
    deleteDeduction: (id: string) => void;
}


const EditableDeductionLines = (props: PropsType) => {

    const {
        deductionsUpdated,
        isEditable,
        deductionTypes,
        deductionsWithTypes,
        deleteDeduction
    } = props;

    const {getDeductionType} = useGetDeductionTypeInfo();

    const validateDeductionType = (deductionTypeId: number, deductionItemId: string): ValidationResult => {
        const otherDeductionItems = deductionsWithTypes.filter(deductionWithType => deductionWithType.deduction.id !== deductionItemId);
        const duplicateDeductionTypeItem = otherDeductionItems.find(deductionWithType => deductionWithType.deductionType.id === deductionTypeId);
        if (!!duplicateDeductionTypeItem) {
            const deductionType = getDeductionType(deductionTypeId, deductionTypes);
            return {
                valid: false,
                message: `Only one line of ${deductionType?.label} deduction can be added to each pay stub. Please use a different deduction type or create a new one  if needed.`
            }
        }
        return {
            valid: true
        }
    }

    return <>
        <Table
            size="small"
            sx={{
                mt: 0,
                '& .MuiTableCell-root': {borderBottom: 'none'},
            }}
        >

            <TableBody>
                {
                    !!deductionsWithTypes && deductionsWithTypes.map((deductionWithType: DeductionWithType) => {
                            return (
                                <TableRow key={deductionWithType.deduction.id}>
                                    <DeductionDisplayEdit
                                        isEditable={isEditable}
                                        deductionItem={deductionWithType.deduction}
                                        deductionTypes={deductionTypes}
                                        deleteItem={deleteDeduction}
                                        validateDeductionType={validateDeductionType}
                                        deductionUpdated={deductionsUpdated}
                                    />
                                </TableRow>
                            );
                        }
                    )
                }

            </TableBody>
        </Table>
    </>
}

export default EditableDeductionLines;