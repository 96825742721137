import {gql} from "@apollo/client";
import {EMPLOYEE_FRAGMENT} from "./getEmployee";

const GET_EMPLOYEES = gql`
    query getEmployees($anonUserId: String!) {
        getEmployees(anonUserId: $anonUserId) {
            ...employeeFields
        }
    }
    ${EMPLOYEE_FRAGMENT}
`;

export default GET_EMPLOYEES;